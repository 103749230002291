import React from 'react';
import Section from '../../../components/Section/Section';
import { Stack } from '@fluentui/react/lib/Stack';
import { Label } from '@fluentui/react/lib/Label';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { Shimmer } from '@fluentui/react/lib/Shimmer';
import { PivotKey } from '../../../models/PivotKey';
import { ICDASHClass, ICDASHDomain } from '../../../models/ICDASH';
import { ICDASHIGClass, ICDASHIGDomain, ICDASHIGScenario } from '../../../models/ICDASHIG';
import ImplementationGuideDocument from '../../../components/ImplementationGuideDocument/ImplementationGuideDocument';
import { ISections } from '../../../models/IDocuments';
import { capitalizeFirst } from '../../../utils/DataUtils';

export interface IClassDomainScenarioTabsProps {
  isLoadingClass: boolean;
  currentClass: ICDASHClass | ICDASHIGClass;
  currentDomain: ICDASHDomain | ICDASHIGDomain;
  currentScenario?: ICDASHIGScenario;
  selectedPivotKey: PivotKey;
  setSelectedPivotKey: (pivotKey: PivotKey) => void;
  isLoadingSections: boolean;
  sections: ISections
  standardType: string;
  version: string;
  standardSubtype?: string;
}

const ClassDomainScenarioTabs: React.FunctionComponent<IClassDomainScenarioTabsProps> = ({ 
  isLoadingClass, currentClass, currentDomain, currentScenario, selectedPivotKey, setSelectedPivotKey, isLoadingSections, sections, standardType, version, standardSubtype
}) => {

  return (
    <Section>
      {!isLoadingClass && currentClass
        ? <>
            <Pivot selectedKey={selectedPivotKey} onLinkClick={(pivotItem: PivotItem) => setSelectedPivotKey(pivotItem.props.itemKey as PivotKey)}>
              <PivotItem itemKey={PivotKey.Class} headerText={currentClass.name}>
                <Stack tokens={{ childrenGap: 20 }}>
                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack>
                      <Label>Name</Label>
                      <span>{currentClass.label}</span>
                    </Stack>
                    {currentClass.ordinal && (
                      <Stack>
                        <Label>Ordinal</Label>
                        <span>{currentClass.ordinal}</span>
                      </Stack>
                    )}
                  </Stack>
                  <Stack>
                    <Label>Description</Label>
                    <span>{currentClass.description}</span>
                  </Stack>
                </Stack>
              </PivotItem>
              
              {currentDomain && (
                <PivotItem itemKey={PivotKey.Domain} headerText={currentDomain.name}>
                  <Stack tokens={{ childrenGap: 20 }}>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <Stack>
                        <Label>Name</Label>
                        <span>{currentDomain.label}</span>
                      </Stack>
                      {currentDomain.ordinal && (
                        <Stack>
                          <Label>Ordinal</Label>
                          <span>{currentDomain.ordinal}</span>
                        </Stack>
                      )}
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }} maxWidth={"50%"}>
                      {currentDomain.description && (
                        <Stack>
                          <Label>Description</Label>
                          <span>{currentDomain.description}</span>
                        </Stack>
                      )}
                      <Stack>
                        <Label>Status</Label>
                        <span>{currentDomain.status || "Final"}</span>
                      </Stack>
                    </Stack>
                  </Stack>
                </PivotItem>
              )}

              {currentScenario && (
                <PivotItem itemKey={PivotKey.Scenario} headerText={currentScenario.scenario}>
                  <Stack tokens={{ childrenGap: 20 }}>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <Stack>
                        <Label>Name</Label>
                        <span>{currentScenario.scenario}</span>
                      </Stack>
                      {currentScenario.ordinal && (
                        <Stack>
                          <Label>Ordinal</Label>
                          <span>{currentScenario.ordinal}</span>
                        </Stack>
                      )}
                    </Stack>
                    {currentScenario.description && (
                      <Stack>
                        <Label>Description</Label>
                        <span>{currentScenario.description}</span>
                      </Stack>
                    )}
                  </Stack>
                </PivotItem>
              )}

              {!isLoadingSections && sections && currentDomain && (
                    sections.sections.map(sectionName => (
                    <PivotItem itemKey={sectionName} headerText={capitalizeFirst(sectionName)}>
                          <ImplementationGuideDocument
                            sectionName={sectionName}
                            standardType={standardType}
                            version={version}
                            standardSubtype={standardSubtype}
                            documentStructure={currentDomain.name}>
                          </ImplementationGuideDocument>
                    </PivotItem>
                    )))}
            </Pivot>
          </>
        : <Shimmer />
      }          
    </Section>
  );
}

export default ClassDomainScenarioTabs;
