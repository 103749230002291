/**
 * Global telemetry provider that instruments all routes for usage tracking.
 * @packageDocumentation 
 */
import React, { Component, Fragment } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai, getAppInsights } from '../../services/TelemetryService';

class TelemetryProvider extends Component {
  state = {
    initialized: false
  };

  componentDidMount() {
    const { initialized } = this.state;
    if (!Boolean(initialized)) {
      ai.initialize();
      const appInsights = getAppInsights();
      appInsights.trackPageView();
      this.setState({ initialized: true });
    }
  }

  render() {
    const { children } = this.props;
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }
}

export default withAITracking(ai.reactPlugin, TelemetryProvider);