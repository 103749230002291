/**
 * Data service for search requests.
 * @packageDocumentation 
 * 
 * @module DataService-Search
 */
import { ISearchParams, ISearchResponse, ISuggestParams, ISuggestResponse } from '../../models/ISearch';
import { BaseDataService } from '../BaseDataService';

export class Search extends BaseDataService {

  public search = async (searchParams: ISearchParams): Promise<ISearchResponse> => {
    if (!searchParams || !searchParams.text) return null;

    const encodedSearchText = encodeURIComponent(searchParams.text);

    let searchUrl = `/api/mdr/search?q=${encodedSearchText}`;
    if (typeof(searchParams.pageSize) !== "undefined") searchUrl += `&pageSize=${searchParams.pageSize}`;
    if (typeof(searchParams.start) !== "undefined") searchUrl += `&start=${searchParams.start}`;
    if (typeof(searchParams.includeFacets) !== "undefined" && searchParams.includeFacets === true) searchUrl += `&facets=true`;
    if (typeof(searchParams.activeFacets) !== "undefined") {
      Object.keys(searchParams.activeFacets).forEach(facetKey => {
        searchUrl += `&${facetKey}=${encodeURIComponent(searchParams.activeFacets[facetKey].join(','))}`
      });
    }

    let result = await this.get<ISearchResponse>(searchUrl);
    return result;
  }

  public suggest = async (suggestParams: ISuggestParams): Promise<ISuggestResponse> => {
    if (!suggestParams || !suggestParams.text) return null;

    const encodedSearchText = encodeURIComponent(suggestParams.text);

    let suggestionUrl = `/api/mdr/suggest?q=${encodedSearchText}`;
    if (typeof(suggestParams.top) !== "undefined") suggestionUrl += `&top=${suggestParams.top}`;
    if (typeof(suggestParams.select) !== "undefined" && suggestParams.select.length > 0) suggestionUrl += `&select=${suggestParams.select.join(',')}`;

    let result = await this.get<ISuggestResponse>(suggestionUrl);
    return result;
  }

}