/**
 * Generic component responsible for rendering an export button allowing users to download content as an Excel or CSV file.
 * @packageDocumentation
 */
import React, { useContext, useState } from "react";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { Stack } from "@fluentui/react/lib/Stack";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import FileSaver from "file-saver";
import { IExportConfig } from "../../models/IExportConfig";
import { logError } from "../../services/TelemetryService";
import { ResponseData } from "../../utils/ResponseData";
import AppContext from "../../components/AppContext/AppContext";

const ExportButton = ({children}: {
  children: IExportConfig[]
}) => {
  const { setError, dataService } = useContext(AppContext);
  const [isDownloading, setIsDownloading] = useState(false);

  const saveFile = async (filename: string, blob: Blob) => {
    try {
      FileSaver.saveAs(blob, filename);
    } catch (error) {
      logError(
        `Unable to download export file named ${filename}`,
        false,
        error
      );
    } finally {
      setIsDownloading(false);
    }
  };

  const fetchFile = async (
    config: IExportConfig
  ): Promise<ResponseData<Blob>> => {
    for (const requiredVariable of config.requiredVariables) {
      if (!requiredVariable) {
        return null;
      }
    }
    return dataService.base.getFile(
      config.request,
      config.exportType.supportedAcceptType
    );
  };

  const downloadAndSaveFile = async (config: IExportConfig) => {
    try {
      setIsDownloading(true);
      let responseData = await fetchFile(config);
      saveFile(
        config.exportFileName ? config.exportFileName : responseData.filename,
        responseData.data
      );
    } catch (error) {
      logError(`Unable to fetch export content`, false, error);
      setIsDownloading(false);
      setError(`Unable to fetch export content`, `${error.message}`);
    }
  };

  return (
    <>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Spinner
          size={SpinnerSize.small}
          styles={{
            root: { visibility: isDownloading ? "visible" : "hidden" },
          }}
        ></Spinner>
        <PrimaryButton
          text="Export"
          iconProps={{ iconName: "CloudDownload" }}
          disabled={isDownloading}
          menuProps={{
            items: children.map((config) => ({
              key: config.exportType.key,
              text: config.exportType.text,
              onClick: () => {
                downloadAndSaveFile(config);
              },
            })),
          }}
        />
      </Stack>
    </>
  );
};

export default ExportButton;
