import { useContext, useEffect, useState } from "react";
import useAsyncData from "../../../hooks/useAsyncData";
import AppContext from "../../../components/AppContext/AppContext";
import { IIntegratedStandard } from "../../../models/IIntegratedStandard";
import { INavItem } from "../../../models/INavItem";

export type IntegratedStandardData = {
  isLoadingItem: boolean;
  currentItem: IIntegratedStandard;
  isLoadingItemImplements: boolean;
  models: INavItem[];
}

export default function useIntegratedStandardData(standard: string, version: string): IntegratedStandardData {
  const { setError, dataService } = useContext(AppContext);
  
  // Fetch Item
  const { 
    isLoading: isLoadingItem, 
    data: currentItem,
    error: itemError,
    replace: replaceItem
  } = useAsyncData<IIntegratedStandard>(null, dataService.integratedStandards.getIntegratedStandard, [standard, version]);
  
  const getItemImplements = async (): Promise<INavItem[]> => {
    if (currentItem && 'models' in currentItem._links) {
      let promises = currentItem._links.models.map(model => {return dataService.nav.getNavLinkFromHref(model.href)});
      return Promise.all(promises);
    }
    else return null;
  }
  const { 
    isLoading: isLoadingItemImplements, 
    data: models,
    error: itemImplementsError,
    replace: replaceItemImplements
  } = useAsyncData<[INavItem]>(null, getItemImplements, [currentItem]);

  // Notify UI of Any Errors
  useEffect(() => {
    const combinedErrors = [itemError].filter(e => e).join('; ');
    if (combinedErrors) {
      setError(`Unable to load data for Integrated Standard`, combinedErrors);
    }
  }, [itemError]);

  let output: IntegratedStandardData = {
    isLoadingItem, currentItem, isLoadingItemImplements, models
  };
  return output;
}
