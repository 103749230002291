import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ISearchResult } from '../../models/ISearch';
import { Stack } from '@fluentui/react/lib/Stack';
import useDebounce from '../../hooks/useDebounce';
import SearchSuggestionItem from './SearchSuggestionItem';
import AppContext from '../AppContext/AppContext';
import { SEARCH_BOX_CONTAINER_ID } from './SearchBox';
import { logError } from '../../services/TelemetryService';

export interface ISearchSuggestionsProps {
  searchText: string;
}

const SUGGESTIONS_COUNT = 5;
const DEBOUNCE_DELAY = 200; //ms
const SUGGESTIONS_SELECT_FIELDS = [
  'label', 'name', 'conceptId', 'type', 'product', 'parentName',
  'href', 'links/href', 'links/title', 'links/type', 'links/linkName'
];

const SearchSuggestions: React.FC<ISearchSuggestionsProps> = ({ searchText }) => {
  const [ showLoading, setShowLoading ] = useState<boolean>(false);
  const [ isFetchingSuggestions, setIsFetchingSuggestions ] = useState<boolean>(false);
  const [ suggestions, setSuggestions ] = useState<ISearchResult[]>([]);
  const { searchParams, dataService } = useContext(AppContext);
  const debouncedSearchText = useDebounce(searchText, DEBOUNCE_DELAY);
  const history = useHistory();
  
  const refreshSuggestions = async (): Promise<void> => {
    if (!debouncedSearchText) setSuggestions([]);
    else {      
      try {
        setIsFetchingSuggestions(true);
        clearSuggestions();

        // Suggestions API endpoint
        let results = await dataService.search.suggest({ text: debouncedSearchText, top: SUGGESTIONS_COUNT, select: SUGGESTIONS_SELECT_FIELDS });
        setSuggestions(results && results.value.length > 0 ? results.value : []);
        
      }
      catch (error) {
        logError(`Unable to retrieve search suggestions`, error);
      }
      finally {
        setIsFetchingSuggestions(false);
        setShowLoading(false);
      }      
    }
  };

  const clearSuggestions = () => {
    setSuggestions([]);
  };

  const onWindowClick = (ev) => {
    if (!document.getElementById(SEARCH_BOX_CONTAINER_ID).contains(ev.target)) {
      clearSuggestions();
    }
  };

  // When debounced text updates, then re-fetch suggestions
  useEffect(() => {
    refreshSuggestions();
  }, [ debouncedSearchText ]);

  // Manage suggestions loading indicator
  useEffect(() => {
    const newShowLoading = isFetchingSuggestions || searchText !== debouncedSearchText;
    if (newShowLoading !== showLoading) {
      setShowLoading(newShowLoading);
    }
  }, [ searchText, debouncedSearchText ]);

  useEffect(() => {
    // When user navigates to another page, clear suggestions
    history.listen(clearSuggestions);

    // Event listener to detect clicks outside search box, clear suggestions
    window.addEventListener("click", onWindowClick);
    return () => window.removeEventListener("click", onWindowClick);
  }, []);

  useEffect(() => {
    if (!!searchParams?.visible) clearSuggestions();
  }, [searchParams]);

  if ((searchParams && searchParams.visible) || !searchText || suggestions.length === 0) return null;
  return (
    <Stack className="search-box-suggestions">
      <Stack className="search-box-suggestions-inner">
        {showLoading
          ? <div className="search-suggestion-item-message">
              Loading...
            </div>
          : <>
            {suggestions.map((sr, index) => (
              <SearchSuggestionItem key={`ss-${index}-${sr.href}`} item={sr} />
            ))}
            {suggestions.length > 0 && (
              <div className="search-suggestion-item-message">
                Press Enter for more results.
              </div>
            )}
          </>
        }
      </Stack>
    </Stack>
  )
}

export default SearchSuggestions;