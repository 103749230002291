import * as React from 'react';
import { ISearchResult } from '../../models/ISearch';
import { Stack } from '@fluentui/react/lib/Stack';
import { Link } from 'react-router-dom';
import { getUiHref } from '../../utils/LinkUtils';

export interface ISearchSuggestionItemProps {
  item: ISearchResult;
}

const SearchSuggestionItem: React.FC<ISearchSuggestionItemProps> = ({ item }) => {
  const tooltip = item.label;
  const line1 = `${item.conceptId ? `(${item.conceptId}) ${item.name}` : item.name} (${item.type})`;
  const line2 = `${item.product || item.parentName}`;
  
  return (
    <Stack className="search-suggestion-item" tokens={{ childrenGap: 5 }}>
      <Link to={getUiHref(item)} title={tooltip}>
        <Stack tokens={{ childrenGap: 5 }}>
          <span className="search-suggestion-item-line1">{line1}</span>
          {line2 && <span className="search-suggestion-item-line2">{line2}</span>}
        </Stack>
      </Link>
    </Stack>
  );
}

export default SearchSuggestionItem;