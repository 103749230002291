/**
 * Generic service which functions as a singleton to provide client-side promise-based caching capabilities.
 * @packageDocumentation 
 * 
 * @module CacheService
 */
import localForage from "localforage";
import { CACHE_ENABLED, CACHE_TTL_SECONDS } from "../config";
import queryString from 'query-string';
import { logVerbose } from "./TelemetryService";
import { getQueryString } from "../utils/LinkUtils";

let _isInitialized = false;
if (!_isInitialized) {
  localForage.config({
    name: "CDISC",
    // Order of preference based on browser support
    driver: [ localForage.INDEXEDDB, localForage.WEBSQL, localForage.LOCALSTORAGE ]
  });
  _isInitialized = true;
}

/**
 * Internal flag to determine if client-side cache is enabled. 
 * In addition to the configuration setting, this function will check if `cache=false` is included in the query string parameters as an additional way to disable cache on a per-request basis.
 */
const _isCacheEnabled = (): boolean => {
  let isEnabled = CACHE_ENABLED;
  try {
    if (CACHE_ENABLED) {
      const parsedQueryString = queryString.parse(getQueryString(window.location), { parseBooleans: true });
      if (parsedQueryString && 'cache' in parsedQueryString) {
        isEnabled = parsedQueryString['cache'] == true;
      }
    }
  }
  catch (error) {
    logVerbose(`Unable to parse query string. ${error.message}`);
  }
  finally {
    return isEnabled;
  }
}

/**
 * Fetch an item from client-side cache by key. 
 * 
 * @param key Key of the item in cache to retrieve.
 * @returns The value in of the cached item. If caching is disabled or there is no data found for the cache key or the cache TTL has lapsed for the cache item, then `null` will be returned.
 */
export const get = async <T> (key: string): Promise<T> => {
  try {
    // If cache is disabled, always return null
    if (!_isCacheEnabled()) return null;

    const valueStr = await localForage.getItem<string>(key);
    if (valueStr) {
      const val = JSON.parse(valueStr);
      if (val) {
        return !(val.expiration && Date.now() > val.expiration) ? val.payload : null;
      }
    }
    return null;
  }
  catch (error) {
    logVerbose(`Error reading from local browser cache with key '${key}'. ${error.message}`);
    return null;
  }
}

/**
 * Add or update an item in client-side cache.
 * 
 * @param key Key of the item in cache to add or update.
 * @param payload Anything.
 * @param ttlSeconds Number of seconds the cache will be considered valid and returned from `get`.
 * 
 * @returns Original payload.
 */
export const set = async <T> (key: string, payload: T, ttlSeconds: number = CACHE_TTL_SECONDS): Promise<T> => {
  try {
    const nowTicks = Date.now();
    const ttlTicks = ttlSeconds * 1000;
    const expiration = (ttlTicks && nowTicks + ttlTicks) || null;
    const valueStr = await localForage.getItem<string>(key) || "{}";
    let cache = JSON.parse(valueStr);
    cache = { payload, expiration };
    await localForage.setItem(key, JSON.stringify(cache));

    // If cache is disabled, then echo payload to allow passthrough ops, otherwise get payload from cache like normal
    return !_isCacheEnabled() ? payload : await get(key);
  }
  catch (error) {
    logVerbose(`Error writing to local browser cache with key '${key}'. ${error.message}`);
    return payload;
  }
};

/**
 * Clear all client-side cache.
 */
export const clear = async (): Promise<void> => {
  return localForage.clear();
};

export default { 
  get, 
  set, 
  clear
};
