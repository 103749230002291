/**
 * Global header component that renders at the very top of the page. Includes the logo, search box and user actions gear menu.
 * @packageDocumentation 
 */
import * as React from 'react';
import { useContext } from 'react';
import AppContext from '../AppContext/AppContext';
import { DEV_PORTAL_URL } from '../../config';
import './Header.scss';
import { Link } from 'react-router-dom';
import { IconButton } from '@fluentui/react/lib/Button';
import SearchBox from '../SearchBox/SearchBox';
import { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';

const Header: React.FC = () => {
  const { searchParams, closeSearch, clearError, authService } = useContext(AppContext);

  const onEditProfileClick = () => {
    authService.editProfile();
  };

  const onLogoutClick = () => {
    authService.logout();
  };

  const onLogoClick = () => {
    closeSearch();
    clearError();
  }

  let userActionItems: IContextualMenuItem[] = [];
  if (DEV_PORTAL_URL) {
    userActionItems.push({ key: 'dev-portal', text: 'API Portal', iconProps: { 'iconName': 'OpenInNewWindow' }, href: DEV_PORTAL_URL, target: '_blank' });
  }
  userActionItems.push({ key: 'edit-profile', text: 'Edit Profile', iconProps: { 'iconName': 'EditContact' }, onClick: onEditProfileClick });
  userActionItems.push({ key: 'logout', text: 'Logout', iconProps: { 'iconName': 'Leave' }, onClick: onLogoutClick });

  return (
    <header className="header">
      <div className="header-left">
        <Link to="/" onClick={onLogoClick}>
          <img src={'./logo.png'} className="header-logo" alt="CDISC Library" />
        </Link>
        <div className="site-title">Data Standards Browser</div>
      </div>
      <div className="header-right">
        <SearchBox searchText={searchParams ? searchParams.text : ""}/>
        <IconButton 
          iconProps={{ iconName: 'Settings' }}
          menuProps={{
            items: userActionItems,              
          }}            
          title="User Actions"
        />
      </div>
    </header>
  );
}

export default Header;