import React from 'react';
import Section from '../../../components/Section/Section';
import { IQRSItem } from '../../../models/IQRS';
import { Stack } from '@fluentui/react/lib/Stack';
import { Shimmer } from '@fluentui/react/lib/Shimmer';
import Responses from './Responses';

export interface IResponsesSectionProps {
  isLoadingItem: boolean;
  currentItem: IQRSItem;
}

const ResponsesSection: React.FunctionComponent<IResponsesSectionProps> = ({
  isLoadingItem, currentItem
}) => {
  const ResponsesComponent = Responses;
  const isLoading = isLoadingItem;
  const hasItemResponses = currentItem && 'responseGroup' in currentItem && 'responses' in currentItem.responseGroup;

  return (
    !isLoading
      ? (hasItemResponses) && <>
        <Section>
          <Stack tokens={{ childrenGap: 20 }}>
            <h4>Responses</h4>
            {!isLoadingItem && currentItem && currentItem.responseGroup.responses && (
              <ResponsesComponent
                isLoading={isLoadingItem}
                responses={currentItem.responseGroup.responses}
              />
            )}
          </Stack>
        </Section>
      </>
      : <Section><Shimmer /></Section>
  );
}

export default ResponsesSection;