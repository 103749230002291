/**
 * Global search box component that allows users to perform searches within the web app. Responsible for instrumenting the suggestions component to display suggested results as the user types.
 * @packageDocumentation 
 */
import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import './SearchBox.scss';
import AppContext from '../AppContext/AppContext';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import SearchSuggestions from './SearchSuggestions';

export interface ISearchBoxProps {
  className?: string;
  searchText?: string;
  authorized?: boolean
}

export const SEARCH_BOX_CONTAINER_ID = "search-box-container";

const SearchBoxComponent: React.FC<ISearchBoxProps> = ({ className, searchText}) => {
  const [ text, setText ] = useState("");
  const { search } = useContext(AppContext);


  const onChange = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
    setText(newValue);
  }

  const onClear = () => {
    setText("");
  }

  const onSearch = (text: string) => {
    if (text) {
      search({ text, visible: true });
    }
  }

  useEffect(() => {
    setText(searchText);
  }, [ searchText ]);

  return (
    <div 
      id={SEARCH_BOX_CONTAINER_ID}
      className={`search-box-container${className ? ` ${className}` : ''}`}
    >
      <SearchBox 
        placeholder="Search" 
        autoComplete="off"
        className="search-box-control"
        onSearch= {onSearch}
        onClear={onClear}
        onChange={onChange}
        value={text}                
      />
      <SearchSuggestions searchText={text} />
    </div>
  )
}

export default SearchBoxComponent;