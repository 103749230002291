import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../components/AppContext/AppContext";
import { getHrefId } from "../../../utils/LinkUtils";
import { IADAM, IADAMDataStructure } from "../../../models/IADAM";

export default function useADAMRouteObserver(
  standard: string, version: string, dataStructureId: string, variableSetId: string, isLoading: boolean, currentItem: IADAM, currentDataStructure: IADAMDataStructure,
  getLink: (standard: string, version: string, classId?: string, datasetId?: string, keepCurrentParams?: boolean) => string
) {
  const history = useHistory();
  const { setError } = useContext(AppContext);

  const getDefaultLink = (): string | undefined => {
    let link: string = undefined;
  
    // do we have an item with data structures, but don't have either a dataStructureId or variableSetId in the url?
    if (currentItem && currentItem.dataStructures && (!dataStructureId || !variableSetId)) {
  
      // no dataStructureId and no variableSetId
      if (!dataStructureId && !variableSetId) {
        const newDataStructureId = getHrefId(currentItem.dataStructures[0]._links.self);
        const newVariableSetId = currentDataStructure && currentDataStructure.analysisVariableSets ? getHrefId(currentDataStructure.analysisVariableSets[0]._links.self) : '';
        link = getLink(standard, version, newDataStructureId, newVariableSetId, true);
      }
  
      // have dataStructureId but no variableSetId
      else if ((currentDataStructure && dataStructureId) && !variableSetId) {
        // const newDataStructure = currentItem.dataStructures.find(ds => getHrefId(ds._links.self) === dataStructureId);
        const newVariableSetId = currentDataStructure && currentDataStructure.analysisVariableSets ? getHrefId(currentDataStructure.analysisVariableSets[0]._links.self) : '';
        
        // does selected class have a dataset?
        if (newVariableSetId) {
          link = getLink(standard, version, dataStructureId, newVariableSetId, true);
        }
      }
    }
  
    return link;
  }

  // Watch Params, Update URL if needed
  useEffect(() => {
    if (!isLoading && currentItem) {
      try {
        const defaultLink = getDefaultLink();
        
        if (defaultLink) {
          history.replace(defaultLink);
        }
      }
      catch (error) {
        setError(`Unable to update ADAM route`, error);
      }
    }
  }, [version, dataStructureId, variableSetId, isLoading, currentItem, currentDataStructure]);
}
