import * as React from 'react';
import { useContext } from 'react';
import AppContext from '../AppContext/AppContext';
import { ISearchResult } from '../../models/ISearch';
import { Stack } from '@fluentui/react/lib/Stack';
import { Link } from 'react-router-dom';
import { getUiHref } from '../../utils/LinkUtils';
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer';

export interface ISearchResultItemProps {
  item?: ISearchResult;
}

const SearchResultItem: React.FC<ISearchResultItemProps> = ({ item }) => {
  const { closeSearch } = useContext(AppContext);

  const onLinkClick = () => {
    closeSearch();
  }

  const renderHighlightedText = () => {
    const highlights = item["@search.highlights"];

    if (highlights && highlights.definition) {
      return <div dangerouslySetInnerHTML={{ __html: highlights.definition.join("<br/>") }} />
    }
    else if (highlights && highlights.description) {
      return <div dangerouslySetInnerHTML={{ __html: highlights.description.join("<br/>") }} />
    }
    else if (item.definition) {
      return <div dangerouslySetInnerHTML={{ __html: item.definition }} />
    }
    else if (item.description) {
      return <div dangerouslySetInnerHTML={{ __html: item.description }} />
    }
    else {
      return "";
    }
  }
  
  return item
    // Render Result Item
    ? <Stack className="search-result-item" tokens={{ childrenGap: 10 }}>
        <Stack tokens={{ childrenGap: 5 }}>
          <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="baseline">
            <Link to={getUiHref(item)} onClick={onLinkClick}>
              <h3 className="search-result-item-title">{item.conceptId ? `(${item.conceptId}) ${item.name}` : item.name}</h3>
            </Link>
            <span>({item.type})</span>
          </Stack>
          <Stack>
            {renderHighlightedText()}
          </Stack>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Link to={getUiHref(item)} onClick={onLinkClick}>{item.parentName}</Link>
        </Stack>
      </Stack>

    // Render Fake Result Item (Shimmers)
    : <Stack className="search-result-item search-result-item-shimmer" tokens={{ childrenGap: 10 }}>
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="baseline">
            <Shimmer shimmerElements={[
              { type: ShimmerElementType.line, width: 300, height: 16 },
              { type: ShimmerElementType.gap, width: '100%' },
            ]} />
          </Stack>
          <Stack tokens={{childrenGap: 5}}>
            <Shimmer shimmerElements={[
              { type: ShimmerElementType.line, width: '100%', height: 8 },
            ]} />
            <Shimmer shimmerElements={[
              { type: ShimmerElementType.line, width: '100%', height: 8 },
            ]} />
          </Stack>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Shimmer shimmerElements={[
            { type: ShimmerElementType.line, width: 50, height: 8 },
            { type: ShimmerElementType.gap, width: 20 },
            { type: ShimmerElementType.line, width: 50, height: 8 },
            { type: ShimmerElementType.gap, width: 20 },
            { type: ShimmerElementType.line, width: 50, height: 8 },
            { type: ShimmerElementType.gap, width: '100%' },
          ]} />
        </Stack>
      </Stack>;
}

export default SearchResultItem;