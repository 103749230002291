import React from 'react';
import Section from '../../../components/Section/Section';
import { useParams, Link, Switch, Route } from 'react-router-dom';
import { getHrefId } from '../../../utils/LinkUtils';
import { Stack } from '@fluentui/react/lib/Stack';
import { Shimmer } from '@fluentui/react/lib/Shimmer';
import { DefaultButton } from '@fluentui/react/lib/Button';
import useIGDocumentUseCases from '../../../hooks/useIGDocumentUseCases';
import useAllExamplesRouteObserver from '../hooks/useAllExamplesRouteObserver';
import ImplementationGuideDocument from '../../../components/ImplementationGuideDocument/ImplementationGuideDocument';

const AllExamplesDiagrams: React.FunctionComponent = () => {
  const { standard, version, useCaseId, subStandard, documentSection } = useParams<any>();
  const getLink = (standard: string, version: string, useCase: string, subStandard?: string, documentSection?: string ): string => {
	  let integratedPrefix = `integrated/${standard}/${version}`;
    let link = `/mdr/${integratedPrefix}/examples/${useCase}`;
    if (subStandard) link += `/${subStandard}`
    if (documentSection) link += `/${documentSection}`
    return link

  }
  // Fetch UseCase data
  const {
    isLoadingUseCases, useCaseData, selectedUseCase, isLoadingUseCaseSections, useCaseSections
  } = useIGDocumentUseCases(standard, version, useCaseId);
  
  useAllExamplesRouteObserver(standard, version, useCaseId, isLoadingUseCases, isLoadingUseCaseSections, useCaseData, useCaseSections, subStandard, documentSection, getLink) 
  return (
    <Section>
      <Stack tokens={{ childrenGap: 10 }}>
        {/* Buttons for Use Cases */}
        <h4>Use Cases</h4>
        <div>
          {!isLoadingUseCases && useCaseData && useCaseData.useCases && useCaseData.useCases.length > 0
            ? useCaseData.useCases.map((currentUseCase, idx) => {
                const currentUseCaseId = getHrefId(currentUseCase._links.self);
                const link = getLink(standard, version, currentUseCaseId);
                const isSelected = selectedUseCase && selectedUseCase.trim().toLowerCase() === currentUseCaseId.trim().toLowerCase();
                return (
                  <Link key={idx} to={link}>
                    <DefaultButton text={currentUseCase.name} className={isSelected ? 'is-selected' : ''} />
                  </Link>
                );
              })
            : <Shimmer />
          }
        </div>
        <div>
          {!isLoadingUseCaseSections && useCaseSections
            ? useCaseSections.map((subDocument) => {
                return subDocument.sections.map((section, idx) => {
                  const section_link = getLink(standard, version, selectedUseCase, subDocument.standardSubtype, section.name);
                  const key = `${subDocument.standardSubtype} ${selectedUseCase} ${section.name} ${idx}`;
                  const label = `${subDocument.standardSubtype.toUpperCase()} ${section.name}`;
                  const isSelected = subStandard && documentSection && subDocument.standardSubtype.trim().toLowerCase() === subStandard.trim().toLowerCase() && section.name.trim().toLowerCase() === documentSection.trim().toLowerCase();
                  return (
                    <Link key={key} to={section_link}>
                      <DefaultButton text={label} className={isSelected ? 'is-selected' : ''}/>
                    </Link>
                  );
                });
              })
            : <Shimmer />
          }

          <Switch>
            {/* Examples and Diagrams */}
            <Route exact path={[
              `/mdr/integrated/:standard/:version/examples/:useCaseId/:subStandard/:documentSection`
            ]} render={(props) => <ImplementationGuideDocument 
                            sectionName={documentSection}
                            standardType={standard}
                            version={`${version}`}
                            useCase={useCaseId}
                            standardSubtype={subStandard}>
                    </ImplementationGuideDocument>}/>
          </Switch>
        </div>
      </Stack>
    </Section>
  );
}

export default AllExamplesDiagrams;
