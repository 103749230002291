import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import List, { IItem } from '../../../components/List/List';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { ISDTMIGVariable } from '../../../models/ISDTMIG';
import { getHrefId } from '../../../utils/LinkUtils';

export interface IVariablesProps {
  variables: ISDTMIGVariable[];
  isLoading: boolean;
}

interface ISDTMIGVariableVM extends ISDTMIGVariable {
  codelistLink: string;
  implementsLink: string;
}

const VariablesIG: React.FunctionComponent<IVariablesProps> = ({ variables, isLoading }) => {
  const [ items, setItems ] = useState<any[]>([]);

  const onRenderCodelistLink = (item?: ISDTMIGVariableVM, index?: number, column?: IColumn) => {
    if (item && item.codelistLink) {
      const codelistLinks: string[] = JSON.parse(item.codelistLink);
      return codelistLinks.map((cl, idx) => {
        const codelistId = getHrefId(cl);
        const packageAndCodelistPath = cl.replace('/mdr/root/ct/', '');
        return (<>
          {idx > 0 ? '; ' : ''}
          <Link key={codelistId} to={`/mdr/ct/latest/packages/${packageAndCodelistPath}`}>{codelistId}</Link>
        </>);
      })
    }
  }

  const onRenderImplementsLink = (item?: ISDTMIGVariableVM, index?: number, column?: IColumn) => {
    if (item && item.implementsLink) {
      const variableLinks = item.implementsLink.split(',');
      return (
        variableLinks.map((vl, idx) => {
          const variableId = vl.substring(vl.lastIndexOf('/') + 1);
          const variablePath = item.implementsLink.replace('/variables/', '?filterFields=name&filterIsExact=true&filter=');
          return <>
            {idx > 0 ? '; ' : ''}
            <Link key={variableId} to={variablePath}>{variableId}</Link>
          </>
        })
      ) 
    }
  }
  
  let columns: IColumn[] = [
    { key: "ordinal", fieldName: "ordinal", name: "Ordinal", minWidth: 70, maxWidth: 70, isResizable: true, isSorted: true, isSortedDescending: false, isMultiline: true },
    { key: "name", fieldName: "name", name: "Name", minWidth: 100, maxWidth: 100, isResizable: true, isMultiline: true },
    { key: "label", fieldName: "label", name: "Label", minWidth: 100, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "description", fieldName: "description", name: "Description", minWidth: 300, isResizable: true, isMultiline: true },
    { key: "simpleDatatype", fieldName: "simpleDatatype", name: "Data Type", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "role", fieldName: "role", name: "Role", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "core", fieldName: "core", name: "Core", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "codelistLink", fieldName: "codelistLink", name: "Code List", minWidth: 70, maxWidth: 200, isResizable: true, onRender: onRenderCodelistLink, isMultiline: true },
    { key: "describedValueDomain", fieldName: "describedValueDomain", name: "Described Value Domain", minWidth: 200, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "implementsLink", fieldName: "implementsLink", name: "Implements", minWidth: 100, maxWidth: 200, isResizable: true, onRender:onRenderImplementsLink, isMultiline: true },
    { key: "valueList", fieldName: "valueList", name: "Value List", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true },
  ];

  const mapItemsWithDefaults = (vars: ISDTMIGVariable[]): IItem[] => {
    return vars.map<IItem>(v => {
      const codelistLink = v._links.codelist ? JSON.stringify(v._links.codelist.map(cl => cl.href)) : '';
      const implementsLink = [
        v._links.modelClassVariable ? v._links.modelClassVariable.href : '',
        v._links.modelDatasetVariable ? v._links.modelDatasetVariable.href : '',
      ].filter(vn => vn).join(',');

      return {
        key: v.name,
        ordinal: v.ordinal ? parseInt(v.ordinal) : 0,
        name: v.name,
        label: v.label,
        description: v.description,
        simpleDatatype: v.simpleDatatype,
        role: v.role,
        core: v.core,
        codelistLink,
        describedValueDomain: v.describedValueDomain,
        implementsLink,
        valueList: v.valueList && v.valueList.map(value => `"${value}"`).join(", "),
      };
    })
  }

  useEffect(() => {
    if (variables) {
      setItems(mapItemsWithDefaults(variables));
    }
  }, [variables]);

  return (
    <List 
      items={items}
      columns={columns} 
      isLoading={isLoading} 
    />
  );
}

export default VariablesIG;
