import { useContext, useEffect, useState } from "react";
import useAsyncData from "../../../hooks/useAsyncData";
import AppContext from "../../../components/AppContext/AppContext";
import { IADAM, IADAMDataStructure, IADAMVariableSet } from "../../../models/IADAM";
import { INavItem } from "../../../models/INavItem";
import { ISections } from "../../../models/IDocuments";

export type ADAMData = {
  isLoadingItem: boolean;
  isLoadingDataStructure: boolean;
  isLoadingVariableSet: boolean;
  isLoadingImplementedBy: boolean;
  isLoadingImplements: boolean;
  isLoadingSections: boolean;
  currentItem: IADAM;
  currentDataStructure: IADAMDataStructure;
  currentVariableSet: IADAMVariableSet;
  currentItemImplementedBy: INavItem[];
  currentItemImplements: INavItem;
  sections: ISections;
}

export default function useADAMData(standard: string, version: string, productSubtype: string, dataStructureId: string, variableSetId: string): ADAMData {
  const { setError } = useContext(AppContext);
  const [ lastFetchedVersion, setLastFetchedVersion ] = useState<string>(null);
  const [ lastFetchedDataStructureId, setLastFetchedDataStructureId ] = useState<string>(null);
  const [ lastFetchedVariableSetId, setLastFetchedVariableSetId ] = useState<string>(null);
  const { dataService } = useContext(AppContext);
  let documentsService = dataService.igDocuments;

  // Fetch Item
  const getStructuresByVersion = async (standard, version): Promise<IADAM> => {
    const data = await dataService.adam.getDataStructuresByVersion(standard, version);
    setLastFetchedVersion(`${standard}/${version}`);
    return data;
  }
  const { 
    isLoading: isLoadingItem, 
    data: currentItem,
    error: itemError,
    replace: replaceItem
  } = useAsyncData<IADAM>(null, getStructuresByVersion, [standard, version]);

  // Fetch Sections
  const {
    isLoading: isLoadingSections,
    data: sections,
    error: sectionsError,
    replace: replaceSections
  } = useAsyncData<ISections>(null, documentsService.getDocumentSections, [standard, version.startsWith("adamig-") ? version.split("adamig-")[1] : version, dataStructureId, productSubtype])

  // Fetch Implemented By
  const getImplementedBy = async (): Promise<INavItem[]> => {
    if (currentItem && 'implementedBy' in currentItem._links && currentItem._links.implementedBy.length > 0) {
      return await Promise.all(currentItem._links.implementedBy.map(itemImplementedByLink => dataService.nav.getNavLinkFromHref(itemImplementedByLink.href)));
    }
    else return [];
  }
  const { 
    isLoading: isLoadingImplementedBy, 
    data: currentItemImplementedBy,
    error: implementedByError,
    replace: replaceImplementedBy
  } = useAsyncData<INavItem[]>([], getImplementedBy, [currentItem]);

  // Fetch Implements
  const getImplements = async (): Promise<INavItem> => {
    if (currentItem && 'model' in currentItem._links) {
      return await dataService.nav.getNavLinkFromHref(currentItem._links.model.href);
    }
    else return null;
  }
  const { 
    isLoading: isLoadingImplements, 
    data: currentItemImplements,
    error: implementsError,
    replace: replaceImplements
  } = useAsyncData<INavItem>(null, getImplements, [currentItem]);

  // Fetch Data Structure
  const getDataStructure = async (standard:string, version: string, dataStructureId: string): Promise<IADAMDataStructure> => {
    const data = await dataService.adam.getDataStructure(standard, version, dataStructureId);
    setLastFetchedDataStructureId(dataStructureId);
    return data; 
  }
  const { 
    isLoading: isLoadingDataStructure, 
    data: currentDataStructure,
    error: dataStructureError,
    replace: replaceDataStructure
  } = useAsyncData<IADAMDataStructure>(null, getDataStructure, [standard, version, dataStructureId]);

  // Fetch Variable Set
  const getVariableSet = async (standard: string, version: string, dataStructureId: string, variableSetId: string): Promise<IADAMVariableSet> => {
    const data = await dataService.adam.getVariableSet(standard, version, dataStructureId, variableSetId);
    setLastFetchedVariableSetId(variableSetId);
    return data; 
  }
  const { 
    isLoading: isLoadingVariableSet, 
    data: currentVariableSet,    
    error: variableSetError,
    replace: replaceVariableSet
  } = useAsyncData<IADAMVariableSet>(null, getVariableSet, [standard, version, dataStructureId, variableSetId]);

  // Clear Version, Data Structure and Variable Set if params become undefined/null
  useEffect(() => {
    if (!version) {
      replaceItem(null);
      replaceImplementedBy([]);
      replaceImplements(null);
    }
    if (!dataStructureId) replaceDataStructure(null);
    if (!variableSetId) replaceVariableSet(null);
  }, [version, dataStructureId, variableSetId]);

  // Notify UI of Any Errors
  useEffect(() => {
    const combinedErrors = [itemError, dataStructureError, variableSetError, implementedByError, implementsError].filter(e => e).join('; ');
    if (combinedErrors) {
      setError(`Unable to load data for ADAM`, combinedErrors);
    }
  }, [itemError, dataStructureError, variableSetError, implementedByError, implementsError]);

  const lastVersionMatches = `${standard}/${version}` === lastFetchedVersion;
  const lastDataStructureIdMatches = dataStructureId === lastFetchedDataStructureId;
  const lastVariableSetIdMatches = variableSetId === lastFetchedVariableSetId;

  return {
    isLoadingItem, isLoadingDataStructure, isLoadingVariableSet, isLoadingImplementedBy, isLoadingImplements, isLoadingSections,
    currentItem: lastVersionMatches ? currentItem : null, 
    currentDataStructure: lastVersionMatches && lastDataStructureIdMatches ? currentDataStructure : null, 
    currentVariableSet: lastVersionMatches && lastDataStructureIdMatches && lastVariableSetIdMatches ? currentVariableSet : null,
    currentItemImplementedBy,
    currentItemImplements,
    sections
  };
}
