import { useContext, useEffect, useState } from "react";
import useAsyncData from "../../../hooks/useAsyncData";
import AppContext from "../../../components/AppContext/AppContext";
import { IQRS, IQRSItem } from "../../../models/IQRS";

export type QRSData = {
  isLoadingElement: boolean;
  isLoadingItem: boolean;
  currentElement: IQRS;
  currentItem: IQRSItem;
}

export default function useQRSData(instrumentId: string, version: string, itemId: string): QRSData {
  const { setError, dataService } = useContext(AppContext);
  const [lastInstrumentId, setLastInstrumentId] = useState<string>(null);
  const [lastVersion, setLastVersion] = useState<string>(null);
  let QRSService = dataService.qrs;

  // Fetch Element
  const {
    isLoading: isLoadingElement,
    data: currentElement,
    error: elementError,
    replace: replaceElement
  } = useAsyncData<IQRS>(null, QRSService.getItemsByVersion, [instrumentId, version]);

  // Fetch Item
  const {
    isLoading: isLoadingItem,
    data: currentItem,
    error: itemError,
    replace: replaceItem
  } = useAsyncData<IQRSItem>(null, QRSService.getItem, [instrumentId, version, itemId]);

  // Clear Version if params become undefined/null, maintain lastVersion
  useEffect(() => {
    if (!version || !instrumentId) {
      replaceElement(null);
    } else {
      if (lastInstrumentId !== instrumentId) {
        setLastInstrumentId(instrumentId);
      }
      if (lastVersion !== version) {
        setLastVersion(version);
      }
    }
  });

  // Clear Item if params become undefined/null
  useEffect(() => {
    if (!itemId) replaceItem(null);
  }, [itemId]);

  // Notify UI of Any Errors
  useEffect(() => {
    const combinedErrors = [elementError, itemError].filter(e => e).join('; ');
    if (combinedErrors) {
      setError(`Unable to load data for QRS`, combinedErrors);
    }
  }, [elementError, itemError]);


  let output: QRSData = {
    isLoadingElement, isLoadingItem,
    currentElement, currentItem,
  };
  if ((lastInstrumentId && lastInstrumentId !== instrumentId) || (lastVersion && lastVersion !== version)) {
    output.currentElement = null;
    output.currentItem = null;
  }
  return output;
}