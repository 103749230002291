import React from 'react';
import Section from '../../../components/Section/Section';
import { ISDTMIGClass, ISDTMIGDataset } from '../../../models/ISDTMIG';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { Shimmer } from '@fluentui/react/lib/Shimmer';
import { ISDTMDataset, ISDTMClass } from '../../../models/ISDTM';
import VariablesIG from './VariablesIG';
import Variables from './Variables';
import { useHistory } from 'react-router-dom';
import { PivotKey } from '../../../models/PivotKey';
import { getQueryString } from '../../../utils/LinkUtils';

export interface IVariablesTabsProps {
  isIG: boolean;
  isLoadingClass: boolean;
  isLoadingDataset: boolean;
  currentClass: ISDTMClass | ISDTMIGClass;
  currentDataset: ISDTMDataset | ISDTMIGDataset;
  selectedPivotKey: PivotKey;
  setSelectedPivotKey: (pivotKey: PivotKey) => void;
}

const VariablesTabs: React.FunctionComponent<IVariablesTabsProps> = ({ 
  isIG, isLoadingClass, isLoadingDataset, currentClass, currentDataset, selectedPivotKey, setSelectedPivotKey
}) => {
  const history = useHistory();
  const VariablesComponent = isIG ? VariablesIG : Variables;
  const isLoading = isLoadingClass || isLoadingDataset;
  const hasClassVariables = currentClass && currentClass.classVariables;
  const hasDatasetVariables = currentDataset && currentDataset.datasetVariables;

  const onPivotLinkClick = (pivotItem: PivotItem) => {
    //Remove query string params if there are any when switching tabs
    if (getQueryString(window.location)) history.replace({ search: '' });
    
    setSelectedPivotKey(pivotItem.props.itemKey as PivotKey);
  };

  return (    
    !isLoading 
      ? (hasClassVariables || hasDatasetVariables) && <>
          <Section>
            <Pivot selectedKey={selectedPivotKey} onLinkClick={onPivotLinkClick}>
              {!isLoadingClass && currentClass && currentClass.classVariables && (
                <PivotItem itemKey={PivotKey.Class} headerText={currentClass.name}>
                  <VariablesComponent 
                    isLoading={isLoadingClass}
                    variables={currentClass.classVariables}
                  />
                </PivotItem>
              )}
              
              {!isLoadingDataset && currentDataset && currentDataset.datasetVariables && (
                <PivotItem itemKey={PivotKey.Dataset} headerText={currentDataset.label}>
                  <VariablesComponent 
                    isLoading={isLoadingClass}
                    variables={currentDataset.datasetVariables}
                  />
                </PivotItem>
              )}
            </Pivot>
          </Section>
        </>
      : <Section><Shimmer /></Section>       
  );
}

export default VariablesTabs;