/**
 * Core component for rendering CT screens.
 * @packageDocumentation 
 */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Section from '../../components/Section/Section';
import { useParams, Link } from 'react-router-dom';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import Codelists from './components/Codelists';
import Terms from './components/Terms';
import { getHrefId, getQueryString } from '../../utils/LinkUtils';
import useCTData from './hooks/useCTData';
import useCTRouteObserver from './hooks/useCTRouteObserver';
import { ILink } from '../../models/ILink';
import { ExportTypes, IExportConfig } from '../../models/IExportConfig';
import AppContext from '../../components/AppContext/AppContext';

export const getLink = (version: string, packageId?: string, codelistId?: string, termId?: string, keepCurrentParams: boolean = false): string => {
  let link = `/mdr/ct/${version}`;
  if (packageId) link += `/packages/${packageId}`;
  if (codelistId) link += `/codelists/${codelistId}`;
  if (termId) link += `/terms/${termId}`;
  if (keepCurrentParams) link += getQueryString(window.location);
  return link;
}

export const getPackageHrefId = (packageSelfLink: ILink): string => {
  return packageSelfLink ? packageSelfLink.href.replace('/mdr/ct/packages/', '').replace('/codelists', '').trim().toLowerCase() : null;
}

export const isLatestVersion = (version: string) => version && version.toLowerCase().trim() === "latest";

const defaultPageTitle = "Controlled Terminology Package";

const CTComponent: React.FunctionComponent = () => {
  const { version, packageId, codelistId, termId } = useParams<any>();
  const [pageTitle, setPageTitle] = useState<string>(defaultPageTitle);
  const {
    isLoadingPackages, isLoadingFullPackage, isLoadingCodelist,
    packages, fullPackage, codelist,
  } = useCTData(version, packageId, codelistId);
  const { dataService } = useContext(AppContext);
  const exportFileName = fullPackage && fullPackage.name ? fullPackage.name.replace(/\s/g, '_') : '';
  const exportConfig = useMemo<IExportConfig[]>(
    () => [
      {
        exportType: ExportTypes.xlsx,
        requiredVariables: [packageId],
        request: `/api/mdr/ct/packages/${packageId}`,
        exportFileName: `${exportFileName}.xlsx`,
      },
      {
        exportType: ExportTypes.csv,
        requiredVariables: [packageId],
        request: `/api/mdr/ct/packages/${packageId}`,
        exportFileName: `${exportFileName}.csv`,
      },
      {
        exportType: ExportTypes.diff,
        requiredVariables: [packageId],
        request: `/api/mdr/diff/ct/${packageId}`,
      },
    ],
    [packageId, exportFileName]
  );

  // Fetch Page Title
  useEffect(() => {
    dataService.nav.getNavLinkFromHref(getLink(version)).then(page => {
      if (page) setPageTitle(page.title)
    });
  }, [version]);

  useCTRouteObserver(version, packageId, codelistId, termId, isLoadingPackages, packages, getLink);

  const renderShimmer = () => {
    return (
      <Shimmer
        styles={{ root: { minHeight: '42px' } }}
        shimmerElements={[
          { type: ShimmerElementType.line, width: 200, height: 30 },
          { type: ShimmerElementType.gap, width: 20, height: 30 },
          { type: ShimmerElementType.line, width: 200, height: 30 },
          { type: ShimmerElementType.gap, width: 20, height: 30 },
          { type: ShimmerElementType.line, width: 200, height: 30 },
          { type: ShimmerElementType.gap, width: '100%', height: 30 },
        ]}
      />
    )
  }

  return (
    <>
      <Section pageTitle={pageTitle} />

      <Section>
        <h4>Packages</h4>
        {!isLoadingPackages && packages
          ? <>
            {packages.map((pkg, idx) => {
              const currentPkgId = getHrefId(pkg._links.self.href.replace('/codelists', ''));
              const pkgLink = getLink(version, currentPkgId);
              const isSelected = packageId && packageId.trim().toLowerCase() === currentPkgId.trim().toLowerCase();

              return (
                <Link key={idx} to={pkgLink}>
                  <DefaultButton text={pkg.name} className={isSelected ? 'is-selected' : ''} />
                </Link>
              )
            })}
          </>
          : renderShimmer()
        }
      </Section>

      {version && packageId && (
        <Codelists
          isHidden={!!codelistId}
          isLoading={isLoadingFullPackage}
          codelists={fullPackage && fullPackage.codelists ? fullPackage.codelists : []}
          packageName={fullPackage ? fullPackage.label : ''}
          exportConfig={exportConfig}
        />
      )}

      {version && packageId && codelistId && (
        <Terms
          isLoading={isLoadingCodelist}
          codelist={codelist}
          packageName={fullPackage ? fullPackage.name : ''}
          packageLink={getLink(version, packageId)}
          termId={termId}
        />
      )}
    </>
  )

}

export default CTComponent;
