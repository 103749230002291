import React, { useEffect, useState } from 'react';
import List, { IItem } from '../../../components/List/List';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { IADAMVariable } from '../../../models/IADAM';
import { onRenderCodelistLinks, parseCodelistLinks } from '../../../components/Link/Link';

export interface IVariablesProps {
  variables: IADAMVariable[];
  isLoading: boolean;
}


const Variables: React.FunctionComponent<IVariablesProps> = ({ variables, isLoading }) => {
  const [ items, setItems ] = useState<any[]>([]);
  
  let columns: IColumn[] = [
    { key: "ordinal", fieldName: "ordinal", name: "Ordinal", minWidth: 70, maxWidth: 70, isResizable: true, isSorted: true, isSortedDescending: false, isMultiline: true },
    { key: "name", fieldName: "name", name: "Name", minWidth: 100, maxWidth: 100, isResizable: true, isMultiline: true },
    { key: "label", fieldName: "label", name: "Label", minWidth: 100, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "description", fieldName: "description", name: "Description", minWidth: 300, isResizable: true, isMultiline: true },
    { key: "core", fieldName: "core", name: "Core", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "simpleDatatype", fieldName: "simpleDatatype", name: "Data Type", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true },
    { key: "codelistLink", fieldName: "codelistLink", name: "CDISC CT Codelist Code(s)", minWidth: 175, maxWidth: 300, isResizable: true, isMultiline: true, onRender: onRenderCodelistLinks },
    { key: "codelistSubmissionValues", fieldName: "codelistSubmissionValues", name: "CDISC CT Codelist Submission Value(s)", minWidth: 255, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "describedValueDomain", fieldName: "describedValueDomain", name: "Described Value Domain(s)", minWidth: 200, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "valueList", fieldName: "valueList", name: "Value List", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: true },
  ];

  const mapItemsWithDefaults = (vars: IADAMVariable[]): IItem[] => {
    return vars.map<IItem>(v => {
      const codelistLinks = parseCodelistLinks(v._links.codelist)
      return {
        key: v.name,
        ordinal: v.ordinal ? parseInt(v.ordinal) : 0,
        name: v.name,
        label: v.label,
        description: v.description,
        core: v.core,
        simpleDatatype: v.simpleDatatype,
        codelistLinks,
        describedValueDomain: v.describedValueDomain,
        valueList: v.valueList && v.valueList.map(value => `"${value}"`).join("; "),
        codelistSubmissionValues: v.codelistSubmissionValues && v.codelistSubmissionValues.map(value => `${value}`).join("; ")
      };
    })
  }

  useEffect(() => {
    if (variables) {
      setItems(mapItemsWithDefaults(variables));
    }
  }, [variables]);

  return (
    <List 
      items={items}
      columns={columns} 
      isLoading={isLoading} 
    />
  );
}

export default Variables;
