/**
 * Generic component for rendering a stack of terms and definitions.
 * @packageDocumentation 
 */
import React from 'react';
import './TermStack.scss';
import { Stack } from '@fluentui/react/lib/Stack';

export interface ITermStackProps {
  terms: { name: string, text: string }[];
}

const TermStack: React.FunctionComponent<ITermStackProps> = ({ terms }) => {
  return (
    <Stack horizontal tokens={{ childrenGap: 20 }} className="termstack">
      <Stack tokens={{ childrenGap: 5 }} className="termstack-left">
        {terms.map((term, idx) => <div key={`name${idx}`}>{term.name}:</div>)}
      </Stack>
      <Stack tokens={{ childrenGap: 5 }} className="termstack-right">
        {terms.map((term, idx) => <div key={`text${idx}`}>{term.text}</div>)}
      </Stack>
    </Stack>
  )
}

export default TermStack;