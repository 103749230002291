import React, { useEffect, useState } from 'react';
import Section from '../../../components/Section/Section';
import { ICTCodelist, ICTTerm } from '../../../models/ICT';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import TermStack from '../../../components/TermStack/TermStack';
import List, { IItem } from '../../../components/List/List';
import { Link } from 'react-router-dom';
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import { Stack } from '@fluentui/react/lib/Stack';
import { Icon } from '@fluentui/react/lib/Icon';
import { formatNullableBool } from '../../../utils/DataUtils';

export interface ITermsProps {
  isLoading: boolean;
  codelist: ICTCodelist;
  termId?: string;
  packageName: string;
  packageLink: string;
}

const Terms: React.FunctionComponent<ITermsProps> = ({ isLoading, codelist, termId, packageName, packageLink }) => {
  const [ items, setItems ] = useState<any[]>([]);
  const [ selectedItem, setSelectedItem] = useState<IItem>();

  const columns: IColumn[] = [
    { key: "conceptId", fieldName: "conceptId", name: "Term", minWidth: 70, maxWidth: 70, isResizable: true, isRowHeader: true, isMultiline: true },
    { key: "submissionValue", fieldName: "submissionValue", name: "Submission Value", minWidth: 100, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "synonyms", fieldName: "synonyms", name: "Synonyms", minWidth: 100, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "definition", fieldName: "definition", name: "Definition", minWidth: 300, isResizable: true, isMultiline: true },
    { key: "preferredTerm", fieldName: "preferredTerm", name: "NCI Preferred Term", minWidth: 200, maxWidth: 300, isResizable: true, isMultiline: true },
  ];

  const mapItemsWithDefaults = (codelists: ICTTerm[]): IItem[] => {
    return codelists.map<IItem>((cl: ICTTerm) => {
      return {
        key: cl.conceptId,
        conceptId: cl.conceptId,
        synonyms: cl.synonyms ? cl.synonyms.join('; ') : '',
        submissionValue: cl.submissionValue,
        definition: cl.definition,
        preferredTerm: cl.preferredTerm,
      }
    })
  }

  const findItem = (codelists: IItem[], ccode: string): IItem => {
    let possibles = codelists.filter(cl => cl.conceptId === ccode);
    return possibles.length === 1 ? possibles[0] : null;
  }

  useEffect(() => {
    if (codelist) {
      let itemsWithDefaults = mapItemsWithDefaults(codelist.terms);
      setItems(itemsWithDefaults);
      if (termId) {
        let item = findItem(itemsWithDefaults, termId);
        if (item) {
          setSelectedItem(item);
        }
      }
    }
  }, [codelist, termId]);

  useEffect(() => {
    let urlPrefix = window.location.href.split("/codelists/")[0];
    if (codelist) {
      urlPrefix =  `${urlPrefix}/codelists/${codelist.conceptId}`;
      if (selectedItem) {
        urlPrefix = `${urlPrefix}/terms/${selectedItem.conceptId}`;
      }
      window.location.href = urlPrefix;
    }
  }, [selectedItem]);

  return (
    <>
      <Section>
        {codelist 
          ? <>              
              <Link to={packageLink} title={`Go back to ${packageName}`}>
                <Icon iconName="Back"
                      style={{fontSize: 16, fontWeight: 700, paddingRight: 8, verticalAlign: 'top'}}
                />
              </Link>
              <h4 style={{marginBottom: 15, display: 'inline-block'}}> {codelist.conceptId}</h4>
              <TermStack terms={[
                  { name: "Extensible", text: formatNullableBool(codelist.extensible) },
                  { name: "Submission Value", text: codelist.submissionValue },
                  { name: "Definition", text: codelist.definition },
                  { name: "NCI Preferred Term", text: codelist.preferredTerm },
                  { name: "Synonyms", text: codelist.synonyms.join(', ') }
                ]} 
              />
            </>
          : <>
              <Stack tokens={{ childrenGap: 10 }} >
                {[1, 2, 3, 4, 5].map(idx =>
                  <Shimmer key={idx} shimmerElements={[
                    { type: ShimmerElementType.line, width: 100, height: 8 },
                    { type: ShimmerElementType.gap, width: 20 },
                    { type: ShimmerElementType.line, width: 400, height: 8 },
                    { type: ShimmerElementType.gap, width: '100%' },
                  ]} />
                )}
              </Stack>
            </>
        }
      </Section>

      <Section>
        <List 
          isLoading={isLoading}
          items={items}
          columns={columns}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </Section>
    </>
  );
}

export default Terms;
