/**
 * Generic React hook for getting IG document use cases.
 * @packageDocumentation 
 * 
 * @module useIGDocumentUseCases
 */
 import { useContext, useEffect, useState } from "react";
 import useAsyncData from "./useAsyncData";
 import AppContext from "../components/AppContext/AppContext";
 import { IUseCases, IUseCaseSections } from "../models/IDocuments";
 
 export type UseCaseData = {
   isLoadingUseCases: boolean;
   useCaseData: IUseCases;
   isLoadingUseCaseSections: boolean;
   useCaseSections: IUseCaseSections[];
   selectedUseCase: string;
 }
 
 export default function useIGDocumentUseCases(
    standardType: string, version: string, useCaseId?: string
  ): UseCaseData {
   const { setError, dataService } = useContext(AppContext);
   const [ lastFetchedUseCase, setLastFetchedUseCase ] = useState<string>(null);
   const [ lastFetchedVersion, setLastFetchedVersion ] = useState<string>(null);
   

   const getSections = async (standardType, version, useCaseId): Promise<IUseCaseSections[]> => {
    const data = await dataService.igDocuments.getSectionsForUseCase(standardType, version, useCaseId);
    setLastFetchedUseCase(useCaseId);
    return data;
   }

   const getUseCases = async (standardType, version): Promise<IUseCases> => {
    const data = await dataService.igDocuments.getUseCasesForStandard(standardType, version)
    setLastFetchedVersion(version);
    return data;
   }

   const { 
     isLoading: isLoadingUseCases,
     data: useCaseData,
     error: useCaseError,
     replace: replaceUseCase
   } = useAsyncData<IUseCases>(null, getUseCases, [standardType, version]);
 
   const {
    isLoading: isLoadingUseCaseSections,
    data: useCaseSections,
    error: useCaseSectionError,
    replace: replaceUseCaseSection
  } = useAsyncData<IUseCaseSections[]>(null, getSections, [standardType, version, useCaseId]);
  
  // Notify UI of Any Errors
  useEffect(() => {
    const combinedErrors = [useCaseError, useCaseSectionError].filter(e => e).join('; ');
    if (combinedErrors) {
      setError(`Unable to load data for use cases`, combinedErrors);
    }
  }, [useCaseError, useCaseSectionError]);
  
  useEffect(() => {
    if (!version) {
      replaceUseCase(null);
    }

    if (!useCaseId) {
      replaceUseCaseSection(null);
    }
  }, [version, useCaseId]);

   let output: UseCaseData = {
     isLoadingUseCases: isLoadingUseCases, 
     useCaseData: lastFetchedVersion === version ? useCaseData : null,
     selectedUseCase: lastFetchedUseCase === useCaseId ? useCaseId : null,
     isLoadingUseCaseSections: isLoadingUseCaseSections,
     useCaseSections: lastFetchedUseCase === useCaseId ? useCaseSections : null
   };
   return output
 }
