import React, { useEffect, useState } from 'react';
import Section from '../../../components/Section/Section';
import { ICTCodelist } from '../../../models/ICT';
import { Link } from 'react-router-dom';
import List, { IItem } from '../../../components/List/List';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import { formatNullableBool } from '../../../utils/DataUtils';
import { IExportConfig } from '../../../models/IExportConfig';

export interface ICodelistsProps {
  isHidden: boolean;
  isLoading: boolean;
  codelists: ICTCodelist[];
  packageName: string;
  exportConfig: IExportConfig[];
}

const Codelists: React.FunctionComponent<ICodelistsProps> = ({ isHidden, isLoading, codelists, packageName, exportConfig }) => {
  const [ items, setItems ] = useState<any[]>([]);
  const [ selectedItem, setSelectedItem ] = useState<IItem>(null);

  const onRenderConceptId = (item?: ICTCodelist, index?: number, column?: IColumn) => {
    const to = (location) => ({ ...location, pathname: `${location.pathname}/codelists/${item.conceptId}` });
    return <Link to={to} onClick={() => setSelectedItem(null)}>{item.conceptId}</Link>;
  }
  
  const columns: IColumn[] = [
    { key: "conceptId", fieldName: "conceptId", name: "Codelist", minWidth: 70, maxWidth: 70, isResizable: true, isRowHeader: true, onRender: onRenderConceptId, isMultiline: true },
    { key: "extensible", fieldName: "extensible", name: "Extensible", minWidth: 70, maxWidth: 70, isResizable: true, isMultiline: true },
    { key: "name", fieldName: "name", name: "Name", minWidth: 200, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "submissionValue", fieldName: "submissionValue", name: "Submission Value", minWidth: 100, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "synonyms", fieldName: "synonyms", name: "Synonyms", minWidth: 100, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "definition", fieldName: "definition", name: "Definition", minWidth: 300, isResizable: true, isMultiline: true },
    { key: "preferredTerm", fieldName: "preferredTerm", name: "NCI Preferred Term", minWidth: 100, maxWidth: 300, isResizable: true, isMultiline: true },
  ];

  const mapItemsWithDefaults = (codelists: ICTCodelist[]): IItem[] => {
    return codelists.map<IItem>(cl => {
      return {
        key: cl.conceptId,
        conceptId: cl.conceptId,
        extensible: formatNullableBool(cl.extensible),
        name: cl.name,
        submissionValue: cl.submissionValue,
        definition: cl.definition,
        preferredTerm: cl.preferredTerm,
        synonyms: cl.synonyms ? cl.synonyms.join('; ') : '',
      }
    })
  }

  useEffect(() => {
    if (codelists) {
      setItems(mapItemsWithDefaults(codelists));
    }
  }, [codelists]);

  return (
    <Section className={isHidden ? "hidden" : ''}>
      {isLoading
        ? <Shimmer styles={{ root: { minHeight: '35px' }}} shimmerElements={[
            { type: ShimmerElementType.line, width: 400, height: 8 },
            { type: ShimmerElementType.gap, width: '100%' }
          ]} />
        : <h4>{packageName}</h4> 
      }
      <List 
        items={items}
        columns={columns} 
        isLoading={isLoading} 
        exportConfig={exportConfig}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    </Section>
  );
}

export default Codelists;
