/**
 * Global layout component responsible for rendering the header, footer and main body. Catches otherwise uncaught application exceptions.
 * @packageDocumentation 
 */
import React, { useContext, useMemo } from 'react';
import './Layout.scss';
import Header from '../Header/Header';
import SidePanel from '../SidePanel/SidePanel';
import AppContext from '../AppContext/AppContext';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { Stack } from '@fluentui/react/lib/Stack';
import MissingResource from '../MissingResource/MissingResource';
import { MISSING_RESOURCE_ERROR } from '../../services/DataService';
import SearchResults from '../SearchResults/SearchResults';

const Layout: React.FC = ({ children }) => {
  const { searchParams, appError, clearError } = useContext(AppContext);
  const isMissingResource = useMemo(() => appError?.message?.indexOf(MISSING_RESOURCE_ERROR) > -1 || false, [appError]);    

  const renderErrorBanner = (): JSX.Element => {
    return (<>
      {appError && <>
        <MessageBar
          styles={{ 
            root: ['error-banner'],
            content: ['error-banner-content']
          }}
          messageBarType={MessageBarType.error}
          onDismiss={() => clearError()}
          dismissButtonAriaLabel="Close"
        >
          <Stack>
            {appError.title && <span><b>{`${appError.title}`}</b></span>}
            {appError.message && <span>{`${appError.message}`}</span>}
          </Stack>
        </MessageBar>
      </>}
    </>)
  }

  return (
    <div className="app">
      <Header />
      <div className="main" id="main">
        {!isMissingResource && renderErrorBanner()}
        {!searchParams?.visible && <>
          <SidePanel className="main-left" />
          <div className="content" data-is-scrollable="true">
            {!isMissingResource ? children : <MissingResource />}
          </div>
        </>}
        <SearchResults searchParams={searchParams} isVisible={searchParams?.visible || false} />
      </div>
    </div>
  );
}

export default Layout;