/**
 * Core component for rendering SDTM screens.
 * @packageDocumentation 
 */
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Switch, Route, useHistory } from "react-router-dom";
import Section from '../../components/Section/Section';
import { useParams, useLocation, Link } from 'react-router-dom';
import { getCDASHIGLink, getSENDIGLink, getSDTMIGLink, getADaMLink } from '../../utils/LinkUtils';
import { Stack } from '@fluentui/react/lib/Stack';
import { Label } from '@fluentui/react/lib/Label';
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import { DefaultButton } from '@fluentui/react/lib/Button';
import ExportButton from '../../components/ExportButton/ExportButton';
import useIntegratedStandardData from './hooks/useIntegratedStandardData';
import { IExportConfig } from '../../models/IExportConfig';
import AppContext from '../../components/AppContext/AppContext';
import CDASHIGComponent from '../Collection/CDASHIG';
import SENDIGComponent from '../Tabulation/SENDIG';
import SDTMIGComponent from '../Tabulation/SDTMIG';
import ADAMComponent from '../Analysis/ADAM';
import AllExamplesDiagrams from './components/AllExamplesDiagrams';
import { SUPPORTED_ACCEPT_TYPES } from '../../services/DataService';

export const getLink = (standard: string, version: string): string => {
  let link = `/mdr/integrated/${standard}/${version}`;
  return link;
}

export const getIntegratedProductSubtypeLink = (standard: string, version: string, productSubtype: string): string => {
  let link = `/mdr/integrated/${standard}/${version}/${productSubtype}`;
  return link;
}

// Override get link methods to prepend integrated standard link
// This prevents navigation to another page when clicking around within a subdocument
export const getIntegratedCDASHLink = (
  standard: string,
  version: string,
  classId?: string,
  domainId?: string,
  scenarioId?: string,
  keepCurrentParams: boolean = false
): string => {
  let integratedPrefix = `integrated/${standard}/${version}`;
  let link = getCDASHIGLink(
    standard,
    version,
    classId,
    domainId,
    scenarioId,
    keepCurrentParams
  );
  return `/mdr/${integratedPrefix}/cdash${
    link.split(`/mdr/cdashig/${version}`)[1]
  }`;
};

export const getIntegratedSENDLink = (
  standard: string,
  version: string,
  classId?: string,
  datasetId?: string,
  keepCurrentParams: boolean = false
): string => {
  let integratedPrefix = `integrated/${standard}/${version}`;
  let link = getSENDIGLink(standard, version, classId, datasetId, keepCurrentParams);
  return `/mdr/${integratedPrefix}/send${
    link.split(`/mdr/sendig/${version}`)[1]
  }`;
};

export const getIntegratedSDTMLink = (
  standard: string,
  version: string,
  classId?: string,
  datasetId?: string,
  keepCurrentParams: boolean = false
): string => {
  let integratedPrefix = `integrated/${standard}/${version}`;
  let link = getSDTMIGLink(standard, version, classId, datasetId, keepCurrentParams);
  return `/mdr/${integratedPrefix}/sdtm${
    link.split(`/mdr/sdtmig/${version}`)[1]
  }`;
};

export const getIntegratedADaMLink = (
  standard: string,
  version: string,
  dataStructureId?: string,
  variableSetId?: string,
  keepCurrentParams: boolean = false
): string => {
  let integratedPrefix = `integrated/${standard}/${version}`;
  let link = getADaMLink(
    standard,
    version,
    dataStructureId,
    variableSetId,
    keepCurrentParams
  );
  return `/mdr/${integratedPrefix}/adam${
    link.split(`/mdr/adam/${version}`)[1]
  }`;
};

const defaultPageTitle = "INTEGRATED STANDARD";

const IntegratedStandardComponent: React.FunctionComponent = () => {
  const { clearError} = useContext(AppContext);
  const history = useHistory();
  
  history.listen(clearError);

  const { standard, version, productSubtype } = useParams<any>();
  const [pageTitle, setPageTitle] = useState<string>(defaultPageTitle);
  const { dataService } = useContext(AppContext);

  const exportConfig = useMemo<IExportConfig[]>(
    () => [
      {
        exportType: {
          key: "gz",
          supportedAcceptType: SUPPORTED_ACCEPT_TYPES.GZ,
          text: "TIG Artifacts",
        },
        requiredVariables: [standard, version],
        request: `/api/mdr/integrated/${standard}/${version}`,
      },
    ],
    [standard, version]
  );


  // Fetch Page Title
  useEffect(() => {
    dataService.nav.getNavLinkFromHref(getLink(standard, version)).then(page => {
      if (page) setPageTitle(page.shortTitle)
    });
  }, [version]);

  // Fetch Integrated Standard Data
  const {
    isLoadingItem, currentItem, isLoadingItemImplements, models
  } = useIntegratedStandardData(standard, version);


  return (
    <>
      <Section pageTitle={pageTitle}>
        {!isLoadingItem && currentItem
          ? <>
            <Stack horizontal horizontalAlign='space-between'>
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack>
                  <Label>Status</Label>
                  <span className={currentItem.registrationStatus === "Draft" ? "draft-status" : ""}>{currentItem.registrationStatus}</span>
                </Stack>
                <Stack>
                  <Label>Effective Date</Label>
                  <span>{currentItem.effectiveDate}</span>
                </Stack>
                  <Stack>
                    <Label>Implements</Label>
                    {!isLoadingItemImplements && models
			? <>
			  {models.map((model, idx) => (
			    <span key={idx}>
                              <Link to={model.href}>
                                {model.shortTitle}
                              </Link>
                      	    </span>
    			))}
			</>
			: <></>
		    }
                  </Stack>
              </Stack>
              <ExportButton>{exportConfig}</ExportButton>
            </Stack>
          </>
          : <>
            {[1, 2].map(idx => (
              <Shimmer key={idx} styles={{ root: { minHeight: 17 } }} shimmerElements={[
                { type: ShimmerElementType.line, width: 50, height: 8 },
                { type: ShimmerElementType.gap, width: 20 },
                { type: ShimmerElementType.line, width: 50, height: 8 },
                { type: ShimmerElementType.gap, width: 20 },
                { type: ShimmerElementType.line, width: 50, height: 8 },
                { type: ShimmerElementType.gap, width: '100%' },
              ]} />
            ))}
          </>
        }
      </Section>
      <Section>
        <div>
          {!isLoadingItem && currentItem
            ? Object.keys(currentItem._links.standards).map((entry, idx) => {
                const isSelected = productSubtype === entry
		const link = getIntegratedProductSubtypeLink(standard, version, entry)
		const buttonTitles = {
			"adam": "ADaM",
			"sdtm": "SDTM",
			"send": "SEND",
			"cdash": "CDASH",
			"qrs": "QRS"
		    }
                return (
		<Link key={idx} to={link} >
                    <DefaultButton text={buttonTitles[entry]} className={isSelected ? 'is-selected' : ''} />
		</Link>
                );
		
              })
            : <Shimmer />
          }
		<Link to={`/mdr/integrated/${standard}/${version}/examples` }>
                    <DefaultButton text={"Examples and Diagrams"} className={productSubtype === "examples" ? 'is-selected' : ''} />
		</Link>
        </div>
      </Section>
      <Section>
	<Switch>
      	{/* CDASH */}
      	<Route exact path={[
        `/mdr/integrated/:standard/:version/:productSubtype(cdash)`,
        `/mdr/integrated/:standard/:version/:productSubtype(cdash)/classes/:classId`,
        `/mdr/integrated/:standard/:version/:productSubtype(cdash)/classes/:classId/domains/:domainId`,
        `/mdr/integrated/:standard/:version/:productSubtype(cdash)/classes/:classId/domains/:domainId/scenarios/:scenarioId`,
        `/mdr/integrated/:standard/:version/:productSubtype(cdash)/domains/:domainId`,
        `/mdr/integrated/:standard/:version/:productSubtype(cdash)/scenarios/:scenarioId`,
      	]} render={(props) => <CDASHIGComponent getLinkOverride={getIntegratedCDASHLink}/>} />

      	{/* SEND */}
      	<Route exact path={[
        `/mdr/integrated/:standard/:version/:productSubtype(send)`,
        `/mdr/integrated/:standard/:version/:productSubtype(send)/classes/:classId`,
        `/mdr/integrated/:standard/:version/:productSubtype(send)/datasets/:datasetId`,
        `/mdr/integrated/:standard/:version/:productSubtype(send)/classes/:classId/datasets/:datasetId`,
        `/mdr/integrated/:standard/:version/:productSubtype(send)/datasets/:datasetId/classes/:classId`
      	]} render={(props) => <SENDIGComponent getLinkOverride={getIntegratedSENDLink}/>} />
      	
	{/* SDTM */}
      	<Route exact path={[
        `/mdr/integrated/:standard/:version/:productSubtype(sdtm)`,
        `/mdr/integrated/:standard/:version/:productSubtype(sdtm)/classes/:classId`,
        `/mdr/integrated/:standard/:version/:productSubtype(sdtm)/datasets/:datasetId`,
        `/mdr/integrated/:standard/:version/:productSubtype(sdtm)/classes/:classId/datasets/:datasetId`,
        `/mdr/integrated/:standard/:version/:productSubtype(sdtm)/datasets/:datasetId/classes/:classId`
      	]} render={(props) => <SDTMIGComponent getLinkOverride={getIntegratedSDTMLink}/>} />
	
	{/* ADAM */}
      	<Route exact path={[
        `/mdr/integrated/:standard/:version/:productSubtype(adam)`,
        `/mdr/integrated/:standard/:version/:productSubtype(adam)/datastructures/:dataStructureId`,
        `/mdr/integrated/:standard/:version/:productSubtype(adam)/variablesets/:variableSetId`,
        `/mdr/integrated/:standard/:version/:productSubtype(adam)/datastructures/:dataStructureId/variablesets/:variableSetId`,
        `/mdr/integrated/:standard/:version/:productSubtype(adam)/variablesets/:variableSetId/datastructures/:dataStructureId`]} render={(props) => <ADAMComponent getLinkOverride={getIntegratedADaMLink}/>} />
        
        {/* Examples and Diagrams */}
        <Route exact path={[
        `/mdr/integrated/:standard/:version/examples`,
        `/mdr/integrated/:standard/:version/examples/:useCaseId`,
        `/mdr/integrated/:standard/:version/examples/:useCaseId/:subStandard/:documentSection`
        ]} render={(props) => <AllExamplesDiagrams/>} />
        </Switch>
      </Section>



    </>
  );
}

export default IntegratedStandardComponent;
