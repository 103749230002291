import { useContext, useEffect } from "react";
import useAsyncData from "../../../hooks/useAsyncData";
import AppContext from "../../../components/AppContext/AppContext";
import { MISSING_RESOURCE_ERROR } from "../../../services/DataService";
import { ICDASH, ICDASHClass, ICDASHDomain } from "../../../models/ICDASH";
import { getHrefId } from "../../../utils/LinkUtils";
import { INavItem } from "../../../models/INavItem";

export type CDASHData = {
  isLoadingItem: boolean;
  isLoadingClass: boolean;
  isLoadingImplementedBy: boolean;
  currentItem: ICDASH;
  currentClass: ICDASHClass;
  currentDomain: ICDASHDomain;
  currentItemImplementedBy: INavItem[];
}

export default function useCDASHData(version: string, classId: string, domainId: string): CDASHData {
  const { setError, dataService } = useContext(AppContext);

  // Fetch Item
  const { 
    isLoading: isLoadingItem, 
    data: currentItem,
    error: itemError,
    replace: replaceItem
  } = useAsyncData<ICDASH>(null, dataService.cdash.getClassesByVersion, [version]);

  // Fetch Implemented By
  const getImplementedBy = async (): Promise<INavItem[]> => {
    if (currentItem && 'implementedBy' in currentItem._links && currentItem._links.implementedBy.length > 0) {
      return await Promise.all(currentItem._links.implementedBy.map(itemImplementedByLink => dataService.nav.getNavLinkFromHref(itemImplementedByLink.href)));
    }
    else return [];
  }
  const { 
    isLoading: isLoadingImplementedBy, 
    data: currentItemImplementedBy,
    error: implementedByError,
    replace: replaceImplementedBy
  } = useAsyncData<INavItem[]>([], getImplementedBy, [currentItem]);

  // Fetch Class
  const { 
    isLoading: isLoadingClass, 
    data: currentClass,
    error: classError,
    replace: replaceClass
  } = useAsyncData<ICDASHClass>(null, dataService.cdash.getClass, [version, classId]);

  // Lookup Domain
  let currentDomain = null;
  if (currentItem && domainId) {
    currentDomain = currentItem.domains.find(d => getHrefId(d._links.self) === domainId);

    // Invalid domainId
    if (!currentDomain) setError(`Invalid domainId`, MISSING_RESOURCE_ERROR);
  }

  // Clear Version and Class if params become undefined/null
  useEffect(() => {
    if (!version) {
      replaceItem(null);
      replaceImplementedBy([]);
    }
    if (!classId) replaceClass(null);
  }, [version, classId]);

  // Notify UI of Any Errors
  useEffect(() => {
    const combinedErrors = [itemError, classError, implementedByError].filter(e => e).join('; ');
    if (combinedErrors) {
      setError(`Unable to load data for CDASH`, combinedErrors);
    }
  }, [itemError, classError, implementedByError]);

  return {
    isLoadingItem, isLoadingClass, isLoadingImplementedBy,
    currentItem, currentClass, currentDomain, currentItemImplementedBy,
  };
}