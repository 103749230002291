import * as React from 'react';
import { useState, useContext, useEffect } from 'react';
import AppContext from '../AppContext/AppContext';
import { SEARCH_RESULTS_PAGE_SIZE } from '../../config';
import { ISearchParams, ISearchResponse } from '../../models/ISearch';
import { Stack } from '@fluentui/react/lib/Stack';
import { ActionButton } from '@fluentui/react/lib/Button';

export interface ISearchPaginationProps {
  searchParams: ISearchParams;
  searchResponse: ISearchResponse;
}

const SearchPagination: React.FC<ISearchPaginationProps> = ({ searchParams, searchResponse }) => {
  const { search } = useContext(AppContext);
  const [ currentPage, setCurrentPage ] = useState<number>(1);
  const [ lastSearchText, setLastSearchText ] = useState<string>("");

  // Reset currentPage to 1 when search text changes
  useEffect(() => {
    if (searchParams && lastSearchText !== searchParams.text) {
      setLastSearchText(searchParams.text);
      setCurrentPage(1);
    }
  });

  const paginate = (pageNumber: number) => {
    search({ ...searchParams, start: (pageNumber - 1) * SEARCH_RESULTS_PAGE_SIZE, pageSize: SEARCH_RESULTS_PAGE_SIZE });
    setCurrentPage(pageNumber);
  }

  const makeButton = (page: number, text?: string) => (
    <ActionButton 
      key={`pagination-${page}-text-${text}`}
      onClick={() => paginate(page)} 
      className={`search-results-pagination-button ${page === currentPage ? " search-results-pagination-selected" : ""}`}
      disabled={page === currentPage}
    >{text ? text : page}</ActionButton>
  );

  let paginationButtons = [];
  if (searchResponse && searchResponse.totalHits > 0) {
    // lastPageNumber: 40; currentPage: 3  -> startPageNumber: 1
    //                     currentPage: 6  -> startPageNumber: 2
    //                     currentPage: 15 -> startPageNumber: 11
    //                     currentPage: 34 -> startPageNumber: 30
    //                     currentPage: 36 -> startPageNumber: 31
    //                     currentPage: 39 -> startPageNumber: 31
    const lastPageNumber = Math.floor(searchResponse.totalHits / SEARCH_RESULTS_PAGE_SIZE);
    let startPageNumber = currentPage > 5  ? lastPageNumber - currentPage < 5 ? lastPageNumber - 9 : currentPage - 4 : 1;
    startPageNumber = startPageNumber < 1 ? 1 : startPageNumber;

    if (startPageNumber > 1) {
      paginationButtons.push(makeButton(1, "First"));
    }
    if (currentPage > 1) {
      paginationButtons.push(makeButton(currentPage - 1, "Prev"));
    }
    for (let newPage = startPageNumber; newPage < startPageNumber + 10 && newPage <= lastPageNumber; newPage++) {
      paginationButtons.push(makeButton(newPage));
    }
    if (currentPage < lastPageNumber) {
      paginationButtons.push(makeButton(currentPage + 1, "Next"));
    }
    // Jumping to last based on totalHits count is unsafe because totalHits is not guaranteed to accurate with a large result set
    // if (lastPageNumber - currentPage > 9) {
    //   paginationButtons.push(makeButton(lastPageNumber, "Last"));
    // }
  }

  return (
    <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 8 }}>
      {paginationButtons}
    </Stack>
  )
}

export default SearchPagination;