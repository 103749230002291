import React from 'react';
import Section from '../../../components/Section/Section';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { Shimmer } from '@fluentui/react/lib/Shimmer';
import Variables from './Variables';
import { useHistory } from 'react-router-dom';
import { PivotKey } from '../../../models/PivotKey';
import { IADAMVariableSet } from '../../../models/IADAM';
import { getQueryString } from '../../../utils/LinkUtils';

export interface IVariablesTabsProps {
  isLoadingVariableSet: boolean;
  currentVariableSet: IADAMVariableSet;
}

const VariablesTabs: React.FunctionComponent<IVariablesTabsProps> = ({ 
  isLoadingVariableSet, currentVariableSet
}) => {
  const history = useHistory();

  const onPivotLinkClick = (pivotItem: PivotItem) => {
    //Remove query string params if there are any when switching tabs
    if (getQueryString(window.location)) history.replace({ search: '' });
  };

  return (    
    !isLoadingVariableSet 
      ? (currentVariableSet && currentVariableSet.analysisVariables) && <>
          <Section>
            <Pivot selectedKey={PivotKey.VariableSet} onLinkClick={onPivotLinkClick}>              
              <PivotItem itemKey={PivotKey.VariableSet} headerText={currentVariableSet.label}>
                <Variables 
                  isLoading={isLoadingVariableSet}
                  variables={currentVariableSet.analysisVariables}
                />
              </PivotItem>
            </Pivot>
          </Section>
        </>
      : <Section><Shimmer /></Section>       
  );
}

export default VariablesTabs;