/**
 * Core component for rendering CDASH screens.
 * @packageDocumentation 
 */
import React, { useState, useEffect, useContext } from 'react';
import Section from '../../components/Section/Section';
import { useParams, useLocation, Link } from 'react-router-dom';
import { getHrefId, getPreferredTab, getQueryString } from '../../utils/LinkUtils';
import { Stack } from '@fluentui/react/lib/Stack';
import { Label } from '@fluentui/react/lib/Label';
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import { PivotKey } from '../../models/PivotKey';
import { ICDASH, ICDASHDomain } from '../../models/ICDASH';
import ClassDomainScenarioSelector from './components/ClassDomainScenarioSelector';
import ClassDomainScenarioTabs from './components/ClassDomainScenarioTabs';
import FieldsTabs from './components/FieldsTabs';
import useCDASHData from './hooks/useCDASHData';
import useCDASHRouteObserver from './hooks/useCDASHRouteObserver';
import AppContext from '../../components/AppContext/AppContext';
import StandardExportButton from '../../components/ExportButton/StandardExportButton';

export const getLink = (standard: string, version: string, classId?: string, domainId?: string, scenarioId: string = '', keepCurrentParams: boolean = false): string => {
  let link = `/mdr/cdash/${version}`;
  if (classId) link += `/classes/${classId}`;
  if (domainId) link += `/domains/${domainId}`;
  if (keepCurrentParams) link += getQueryString(window.location);
  return link;
}

export const getDomainsForClassId = (classId: string, currentItem: ICDASH): ICDASHDomain[] => {
  let domainsForClass = [];
  if (classId && currentItem) {
    domainsForClass = currentItem.domains.filter(d => getHrefId(d._links.parentClass) === classId);
  }
  return domainsForClass;
}

const defaultPageTitle = "CDASH";

const CDASHComponent: React.FunctionComponent = () => {
  const location = useLocation();
  const { standard, version, classId, domainId } = useParams<any>();
  const [pageTitle, setPageTitle] = useState<string>(defaultPageTitle);
  const [selectedMetaPivotKey, setSelectedMetaPivotKey] = useState<PivotKey>(PivotKey.Class);
  const [selectedListPivotKey, setSelectedListPivotKey] = useState<PivotKey>(PivotKey.Class);
  const { dataService } = useContext(AppContext);

  // Fetch Page Title
  useEffect(() => {
    dataService.nav.getNavLinkFromHref(getLink(standard, version)).then(page => {
      if (page) setPageTitle(page.shortTitle)
    });
  }, [version]);

  // Fetch CDASH Data
  const {
    isLoadingItem, isLoadingClass, isLoadingImplementedBy,
    currentItem, currentClass, currentDomain, currentItemImplementedBy,
  } = useCDASHData(version, classId, domainId);

  // Handle CDASH Route Changes
  useCDASHRouteObserver(standard, version, classId, domainId, currentItem, getLink);

  // Update Selected Tab
  useEffect(() => {
    const preferredTab = getPreferredTab(location);
    if (preferredTab) {
      setSelectedMetaPivotKey(preferredTab);
      setSelectedListPivotKey(preferredTab);
    }
    else if (currentDomain) {
      setSelectedMetaPivotKey(PivotKey.Domain);
      setSelectedListPivotKey(PivotKey.Domain);
    }
    else {
      setSelectedMetaPivotKey(PivotKey.Class);
      setSelectedListPivotKey(PivotKey.Class);
    }
  }, [currentClass, currentDomain]);

  return (
    <>
      <Section pageTitle={pageTitle}>
        {!isLoadingItem && !isLoadingImplementedBy && currentItem
          ? <>
            <Stack horizontal horizontalAlign='space-between'>
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack>
                  <Label>Status</Label>
                  <span className={currentItem.registrationStatus === "Draft" ? "draft-status" : ""}>{currentItem.registrationStatus}</span>
                </Stack>
                <Stack>
                  <Label>Effective Date</Label>
                  <span>{currentItem.effectiveDate}</span>
                </Stack>
                {currentItemImplementedBy.length > 0 && (
                  <Stack>
                    <Label>Implemented By</Label>
                    <Stack horizontal tokens={{ childrenGap: 14 }}>
                      {currentItemImplementedBy.map((link, index) => (
                        <Link key={`cdash-ib-${index}-${link.href}`} to={link.href}>
                          {link.shortTitle}
                        </Link>
                      ))}
                    </Stack>
                  </Stack>
                )}
              </Stack>
              <StandardExportButton
                pageTitle={pageTitle}
                standard={standard}
                version={version}
              />
            </Stack>
          </>
          : <>
            {[1, 2].map(idx => (
              <Shimmer key={idx} styles={{ root: { minHeight: 17 } }} shimmerElements={[
                { type: ShimmerElementType.line, width: 50, height: 8 },
                { type: ShimmerElementType.gap, width: 20 },
                { type: ShimmerElementType.line, width: 50, height: 8 },
                { type: ShimmerElementType.gap, width: 20 },
                { type: ShimmerElementType.line, width: 50, height: 8 },
                { type: ShimmerElementType.gap, width: '100%' },
              ]} />
            ))}
          </>
        }
      </Section>

      <ClassDomainScenarioSelector
        classId={classId}
        domainId={domainId}
        classes={currentItem ? currentItem.classes : null}
        domains={getDomainsForClassId(classId, currentItem)}
        isLoadingClass={isLoadingClass}
        isLoadingItem={isLoadingItem}
        standard={standard}
        version={version}
        getLink={getLink}
      />

      {undefined !== isLoadingClass && (
        <ClassDomainScenarioTabs
          currentClass={currentClass}
          currentDomain={currentDomain}
          isLoadingClass={isLoadingClass}
          selectedPivotKey={selectedMetaPivotKey}
          setSelectedPivotKey={setSelectedMetaPivotKey}
          isLoadingSections={false}
          sections={null}
          standardType={'cdash'}
          version={version}
        />
      )}

      {undefined !== isLoadingClass && (
        <FieldsTabs
          isIG={false}
          currentClass={currentClass}
          currentDomain={currentDomain}
          isLoadingClass={isLoadingClass}
          selectedPivotKey={selectedListPivotKey}
          setSelectedPivotKey={setSelectedListPivotKey}
        />
      )}
    </>
  );
}

export default CDASHComponent;
