import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import List, { IItem } from '../../../components/List/List';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { IQRSResponse } from '../../../models/IQRS';
import { getHrefId } from '../../../utils/LinkUtils';
import { ILink } from '../../../models/ILink';
import { getLatestCTLink } from '../QRS';

export interface IResponsesProps {
  responses: IQRSResponse[];
  isLoading: boolean;
}

interface IQRSResponseVM extends IQRSResponse {
  ORRESLink: string;
  STRESCLink: string;
}

const Responses: React.FunctionComponent<IResponsesProps> = ({ responses, isLoading }) => {
  const [items, setItems] = useState<any[]>([]);

  const onRenderORRESLink = (item?: IQRSResponseVM, index?: number, column?: IColumn) => {
    if (item && item.ORRESLink) {
      const codelistLink: ILink = JSON.parse(item.ORRESLink);
      return (<>{getLatestCTLink(codelistLink)}</>);
    }
  }

  const onRenderSTRESCLink = (item?: IQRSResponseVM, index?: number, column?: IColumn) => {
    if (item && item.STRESCLink) {
      const codelistLink: ILink = JSON.parse(item.STRESCLink);
      return (<>{getLatestCTLink(codelistLink)}</>);
    }
  }

  let columns: IColumn[] = [
    { key: "ordinal", fieldName: "ordinal", name: "Ordinal", minWidth: 70, maxWidth: 70, isResizable: true, isSorted: true, isSortedDescending: false, isMultiline: false },
    { key: "originalResult", fieldName: "originalResult", name: "Original Result", minWidth: 70, maxWidth: 300, isResizable: true, isMultiline: true },
    { key: "ORRESLink", fieldName: "ORRESLink", name: "ORRES Code", minWidth: 70, maxWidth: 140, isResizable: true, onRender: onRenderORRESLink, isMultiline: false },
    { key: "standardizedResult", fieldName: "standardizedResult", name: "Standardized Result", minWidth: 70, maxWidth: 200, isResizable: true, isMultiline: false },
    { key: "STRESCLink", fieldName: "STRESCLink", name: "STRESC Code", minWidth: 70, maxWidth: 140, isResizable: true, onRender: onRenderSTRESCLink, isMultiline: false },
  ];

  const mapItemsWithDefaults = (responses: IQRSResponse[]): IItem[] => {
    return responses.map<IItem>(response => {
      const ORRESLink = response._links.responseORRES ? JSON.stringify(response._links.responseORRES.href) : '';
      const STRESCLink = response._links.responseSTRESC ? JSON.stringify(response._links.responseSTRESC.href) : '';

      return {
        key: response.ordinal,
        ordinal: response.ordinal ? parseInt(response.ordinal) : 0,
        ORRESLink,
        originalResult: response.originalResult,
        STRESCLink,
        standardizedResult: response.standardizedResult
      };
    })
  }

  useEffect(() => {
    if (responses) {
      setItems(mapItemsWithDefaults(responses));
    }
  }, [responses]);

  return (
    <List
      items={items}
      columns={columns}
      isLoading={isLoading}
    />
  );
}

export default Responses;