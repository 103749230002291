/**
 * Data service for CDASHIG requests.
 * @packageDocumentation
 *
 * @module DataService-CDASHIG
 */
import { ICDASHIG, ICDASHIGClass } from "../../models/ICDASHIG";
import { BaseDataService } from "../BaseDataService";

export class CDASHIG extends BaseDataService {
  public getClassesByVersion = async (
    standard: string,
    version: string
  ): Promise<ICDASHIG> => {
    if (!version) return null;
    const url = `/api/mdr/${
      standard === "tig" ? `integrated/${standard}/${version}/cdash` : `cdashig/${version}`
    }/classes?expand=true`;
    const result = await this.get<ICDASHIG>(url);
    return result;
  };

  public getDomainsByVersion = async (
    standard: string,
    version: string
  ): Promise<ICDASHIG> => {
    if (!version) return null;
    const url = `/api/mdr/${
      standard === "tig" ? `integrated/${standard}/${version}/cdash` : `cdashig/${version}`
    }/domains?expand=true`;
    const result = await this.get<ICDASHIG>(url);
    return result;
  };

  public getClass = async (
    standard: string,
    version: string,
    classId: string
  ): Promise<ICDASHIGClass> => {
    if (!version || !classId) return null;
    const url = `/api/mdr/${
      standard === "tig" ? `integrated/${standard}/${version}/cdash` : `cdashig/${version}`
    }/classes/${classId}`;
    const result = await this.get<ICDASHIGClass>(url);
    return result;
  };
}
