/**
 * Core component for rendering dashboard screen (home page).
 * @packageDocumentation 
 */
import React from 'react';
import Section from '../../components/Section/Section';
import { DEV_PORTAL_URL } from '../../config';
import './Dashboard.scss';

const Dashboard: React.FunctionComponent = () => {
    return (
        <div className="dashboard">
            <Section className="welcome" pageTitle={"Dashboard"}>
                <img src={'./logo.png'} className="dashboard-logo" alt="CDISC Library" />
                <h2>Welcome to the CDISC Library!</h2>
                <p>The single, trusted, authoritative source of CDISC standards metadata.</p>
            </Section>

            <Section className="resources">
                <h3>Helpful CDISC Library Resources</h3>
                <ul>
                    {DEV_PORTAL_URL && <li><a target="_blank" href={DEV_PORTAL_URL} rel="noopener noreferrer">CDISC Library API Portal</a></li>}
                    <li><a target="_blank" href="https://api.developer.library.cdisc.org/api-details" rel="noopener noreferrer">API Documentation</a></li>
                    <li><a target="_blank" href="https://github.com/cdisc-org/library-xml" rel="noopener noreferrer">CDISC Library XML Schema</a></li>
                    <li><a target="_blank" href="https://www.cdisc.org/cdisc-library" rel="noopener noreferrer">CDISC Library Website Landing Page</a></li>
                    <li><a target="_blank" href="https://learnstore.cdisc.org/Course/index?tags=Subject%7CCDISC%20Library" rel="noopener noreferrer">CDISC Library on CDISC Learning Management System</a></li>
                    <li><a target="_blank" href="https://www.cdisc.org/cdisc-library/api-account-product-inquiry" rel="noopener noreferrer">Product Inquiry Form</a></li>
                    <li><a target="_blank" href="https://jira.cdisc.org/servicedesk/customer/portal/2" rel="noopener noreferrer">Service Desk</a></li>
                    <li><a target="_blank" href="https://wiki.cdisc.org/x/kwlcB" rel="noopener noreferrer">Release Notes</a></li>
                    <li><a target="_blank" href="https://wiki.cdisc.org/x/1QtcB" rel="noopener noreferrer">How-to Articles</a></li>
                </ul>
            </Section>
        </div>
    );
}

export default Dashboard;
