import { useContext, useEffect, useState } from "react";
import useAsyncData from "../../../hooks/useAsyncData";
import AppContext from "../../../components/AppContext/AppContext";
import { ISDTMIG, ISDTMIGClass, ISDTMIGDataset } from "../../../models/ISDTMIG";
import { ISENDIG, ISENDIGClass, ISENDIGDataset } from "../../../models/ISENDIG";
import { TabulationType } from "../../../models/TabulationType";
import { INavItem } from "../../../models/INavItem";
import { ISections } from "../../../models/IDocuments";

export type SDTMIGData = {
  isLoadingItem: boolean;
  isLoadingClass: boolean;
  isLoadingDataset: boolean;
  isLoadingItemImplements: boolean;
  isLoadingSections: boolean;
  currentItem: ISDTMIG | ISENDIG;
  currentClass: ISDTMIGClass | ISENDIGClass;
  currentDataset: ISDTMIGDataset | ISENDIGDataset;
  currentItemImplements: INavItem;
  sections: ISections
}

export default function useSDTMData(type: TabulationType, standard: string, version: string, productSubtype: string, classId: string, datasetId: string): SDTMIGData {
  const { setError, dataService } = useContext(AppContext);
  const [ lastVersion, setLastVersion ] = useState<string>(null);
  let documentsService = dataService.igDocuments;
  let sdtmService = null;
  switch (type){
    case TabulationType.SDTMIG: sdtmService = dataService.sdtmig; break;
    case TabulationType.SENDIG: sdtmService = dataService.sendig; break;
  }

  // Fetch Item
  const { 
    isLoading: isLoadingItem, 
    data: currentItem,
    error: itemError,
    replace: replaceItem
  } = useAsyncData<ISDTMIG | ISENDIG>(null, sdtmService.getClassesByVersion, [standard, version]);

  // Fetch Sections
  const {
    isLoading: isLoadingSections,
    data: sections,
    error: sectionsError,
    replace: replaceSections
  } = useAsyncData<ISections>(null, documentsService.getDocumentSections, [standard, version, datasetId, productSubtype])
  // Fetch Implements
  const getItemImplements = async (): Promise<INavItem> => {
    if (currentItem && 'model' in currentItem._links) {
      return await dataService.nav.getNavLinkFromHref(currentItem._links.model.href);
    }
    else return null;
  }
  const { 
    isLoading: isLoadingItemImplements, 
    data: currentItemImplements,
    error: itemImplementsError,
    replace: replaceItemImplements
  } = useAsyncData<INavItem>(null, getItemImplements, [currentItem]);

  // Fetch Class
  const { 
    isLoading: isLoadingClass, 
    data: currentClass,
    error: classError,
    replace: replaceClass
  } = useAsyncData<ISDTMIGClass | ISENDIGClass>(null, sdtmService.getClass, [standard, version, classId]);

  // Fetch Dataset
  const { 
    isLoading: isLoadingDataset, 
    data: currentDataset,    
    error: datasetError,
    replace: replaceDataset
  } = useAsyncData<ISDTMIGDataset | ISENDIGDataset>(null, sdtmService.getDataset, [standard, version, datasetId]);

  // Clear Version if params become undefined/null, maintain lastVersion
  useEffect(() => {
    if (!version) {
      replaceItem(null);
      replaceItemImplements(null);
    } 
    else if (lastVersion !== version) {
      setLastVersion(version);
    }
  });
  
  // Clear Class and Dataset if params become undefined/null
  useEffect(() => {
    if (!classId) replaceClass(null);
    if (!datasetId) replaceDataset(null);
  }, [classId, datasetId]);

  // Notify UI of Any Errors
  useEffect(() => {
    const combinedErrors = [itemError, classError, datasetError, itemImplementsError].filter(e => e).join('; ');
    if (combinedErrors) {
      setError(`Unable to load data for SDTMIG/SENDIG`, combinedErrors);
    }
  }, [itemError, classError, datasetError, itemImplementsError]);

  
  let output: SDTMIGData = {
    isLoadingItem, isLoadingClass, isLoadingDataset, isLoadingItemImplements, isLoadingSections,
    currentItem, currentClass, currentDataset, currentItemImplements, sections
  };
  if (lastVersion && lastVersion !== version) {
    output.currentItem = null;
    output.currentClass = null;
    output.currentDataset = null;
    output.currentItemImplements = null;
  }
  return output;
}
