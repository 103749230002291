/**
 * Data service for ADAM requests.
 * @packageDocumentation 
 * 
 * @module DataService-IGDocuments
 */
 import { IIntegratedStandard } from '../../models/IIntegratedStandard';
 import { BaseDataService } from '../BaseDataService';
 
 export class IntegratedStandards extends BaseDataService {
 
   public getIntegratedStandard = async (documentType: string, version: string): Promise<IIntegratedStandard> => {
     if (!version) return null;
     let result = await this.get<IIntegratedStandard>(`/api/mdr/integrated/${documentType}/${version}`);
     return result;
   }

  }
