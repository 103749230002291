/**
 * Data service for SDTMIG requests.
 * @packageDocumentation
 *
 * @module DataService-SDTMIG
 */
import { ISDTMIG, ISDTMIGClass, ISDTMIGDataset } from "../../models/ISDTMIG";
import { BaseDataService } from "../BaseDataService";

export class SDTMIG extends BaseDataService {
  public getClassesByVersion = async (
    standard: string,
    version: string
  ): Promise<ISDTMIG> => {
    if (!version) return null;
    const url = `/api/mdr/${
      standard === "tig" ? `integrated/${standard}/${version}/sdtm` : `sdtmig/${version}`
    }/classes?expand=true`;
    const result = await this.get<ISDTMIG>(url);
    return result;
  };

  public getDatasetsByVersion = async (
    standard: string,
    version: string
  ): Promise<ISDTMIG> => {
    if (!version) return null;
    const url = `/api/mdr/${
      standard === "tig" ? `integrated/${standard}/${version}/sdtm` : `sdtmig/${version}`
    }/datasets?expand=true`;
    const result = await this.get<ISDTMIG>(url);
    return result;
  };

  public getClass = async (
    standard: string,
    version: string,
    classId: string
  ): Promise<ISDTMIGClass> => {
    if (!version || !classId) return null;
    const url = `/api/mdr/${
      standard === "tig" ? `integrated/${standard}/${version}/sdtm` : `sdtmig/${version}`
    }/classes/${classId}`;
    const result = await this.get<ISDTMIGClass>(url);
    return result;
  };

  public getDataset = async (
    standard: string,
    version: string,
    datasetId: string
  ): Promise<ISDTMIGDataset> => {
    if (!version || !datasetId) return null;
    const url = `/api/mdr/${
      standard === "tig" ? `integrated/${standard}/${version}/sdtm` : `sdtmig/${version}`
    }/datasets/${datasetId}`;
    const result = await this.get<ISDTMIGDataset>(url);
    return result;
  };
}
